<template>
  <div @click="handleLogout" class="flex items-center gap-2 cursor-pointer">
    <LogOut :size="18" />
    Log Out
  </div>
</template>
<script setup>
import { LogOut } from 'lucide-vue-next';
import { useAuthStore } from '@/stores/authStore';
import Api from '@/utils/ApiClient';
const authStore = useAuthStore();
const handleLogout = async () => {
  try {
    const response = await Api.post('auth/logout');
    if (response.status == 200) {
      await authStore.logout();
    }
  } catch (error) {
    console.error('Logout failed:', error);
  }
};
</script>
