<template>
  <div v-if="teamInvitations.length > 0" class="space-y-6">
    <section>
      <p class="text-xs md:text-sm text-gray-500 dark:text-gray-400 pb-4">
        These people have been invited to your team via an invitation email.
        They may join the team by accepting the invitation.
      </p>

      <div
        class="overflow-x-auto bg-white dark:bg-gray-800 rounded-lg shadow-sm grid"
      >
        <table class="min-w-full table-auto">
          <thead>
            <tr
              class="bg-gray-50 dark:bg-gray-700 text-gray-600 dark:text-gray-200"
            >
              <th
                class="px-2 md:px-6 py-1 md:py-3 text-left text-xs md:text-sm font-medium"
              >
                Email
              </th>
              <th
                class="px-2 md:px-6 py-1 md:py-3 text-left text-xs md:text-sm font-medium"
              >
                Role
              </th>
              <th
                class="px-2 md:px-6 py-1 md:py-3 text-left text-xs md:text-sm font-medium"
              >
                Invited By
              </th>
              <th
                class="px-2 md:px-6 py-1 md:py-3 text-left text-xs md:text-sm font-medium"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
            <tr
              v-for="invitation in teamInvitations"
              :key="invitation.id"
              class="bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <td
                class="px-2 md:px-6 py-1 md:py-3 text-xs md:text-sm text-gray-900 dark:text-gray-100 truncate"
              >
                {{ invitation.email }}
              </td>
              <td
                class="px-2 md:px-6 py-1 md:py-3 text-xs md:text-sm text-gray-500 dark:text-gray-400"
              >
                {{ toTitleCase(invitation.role) }}
              </td>
              <td
                class="px-2 md:px-6 py-1 md:py-3 text-xs md:text-sm text-gray-500 dark:text-gray-400 truncate"
              >
                {{ invitation.invited_by }}
              </td>
              <td class="px-2 md:px-6 py-1 md:py-3 text-xs md:text-sm">
                <button
                  class="text-red-500 border border-red-500 hover:bg-red-100 dark:hover:bg-red-600 dark:hover:text-white rounded-md p-1 md:p-2 transition duration-200 text-xs md:text-sm"
                  @click="openConfirmationModal(invitation)"
                >
                  Cancel
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>

  <p
    v-else
    class="text-sm sm:text-base text-gray-500 dark:text-gray-400 text-center py-4"
  >
    No pending invitations.
  </p>
  <ConfirmationModal
    :show="showConfirmationModal"
    @close="showConfirmationModal = false"
  >
    <template #title>Confirm Remove</template>
    <template #content>
      Are you sure you want to cancel the invitation of
      <strong>{{ invitationToCancel?.email }}</strong> ? This action cannot be
      undone.
    </template>
    <template #footer>
      <button
        class="bg-gray-200 text-gray-700 px-4 py-2 mr-2 rounded"
        @click="showConfirmationModal = false"
      >
        Cancel
      </button>
      <button
        class="bg-red-600 text-white px-4 py-2 rounded"
        :disabled="loading"
        @click="cancelTeamInvitation"
      >
        <span v-if="loading">Cancelling...</span>
        <span v-else>Confirm</span>
      </button>
    </template>
  </ConfirmationModal>
</template>

<script setup>
import ConfirmationModal from '@/components/ConfirmationModal.vue';
import { useTeamStore } from '@/stores/teamStore';
import { toTitleCase } from '@/utils/helperFunctions';
import Api from '@/utils/ApiClient';
import { ref, watch } from 'vue';
import { toast } from 'vue3-toastify';

const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const teamInvitations = ref([]);
const showConfirmationModal = ref(false);
const invitationToCancel = ref();
const loading = ref(false);
const fetchTeamInvites = async () => {
  try {
    const response = await Api.get(`/invitation`);

    if (response.data) {
      teamInvitations.value = response.data;
    }
  } catch (error) {
    teamInvitations.value = [];
  }
};
const openConfirmationModal = (invitaion) => {
  invitationToCancel.value = invitaion;
  showConfirmationModal.value = true;
};
const cancelTeamInvitation = async () => {
  try {
    loading.value = true;
    const response = await Api.delete(
      `/invitation/${invitationToCancel.value.invitation_id}`,
    );
    if (response.data) {
      toast.success(response.data);
    }
    fetchTeamInvites();
  } catch (error) {
    toast.error(error.response.data.detail);
    console.error('Error cancelling invitation:', error);
  } finally {
    loading.value = false;
    invitationToCancel.value = null;
    showConfirmationModal.value = false;
  }
};

watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam?.id) {
      currentTeam.value = newTeam;
      fetchTeamInvites();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
