<template>
  <transistion>
    <div v-if="loading" class="flex justify-center items-center h-screen">
      <LoaderCircle />
    </div>
    <div v-else>
      <AuthenticationCard v-if="!invitationExpired">
        <template #logo>
          <AuthenticationCardLogo />
        </template>

        <form @submit.prevent="submit" novalidate>
          <div>
            <InputLabel for="firstName" value="First Name" />
            <TextInput
              id="firstName"
              v-model="firstName"
              type="text"
              class="mt-1 block w-full"
              required
              autofocus
              autocomplete="name"
            />
            <InputError class="mt-2" :message="errors.firstName" />
          </div>
          <div class="mt-4">
            <InputLabel for="lastName" value="Last Name" />
            <TextInput
              id="lastName"
              v-model="lastName"
              type="text"
              class="mt-1 block w-full"
              required
              autofocus
              autocomplete="name"
            />
            <InputError class="mt-2" :message="errors.lastName" />
          </div>

          <div class="mt-4 relative">
            <div class="flex items-center">
              <div class="flex-1">
                <InputLabel for="password" value="Password" />
                <TextInput
                  id="password"
                  v-model="password"
                  type="password"
                  class="mt-1 block w-full"
                  required
                  autocomplete="new-password"
                />
              </div>
              <button
                type="button"
                @click="showPasswordInstructions = !showPasswordInstructions"
                class="ml-2 mt-6 text-gray-500 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="12" cy="12" r="10" />
                  <line x1="12" y1="16" x2="12" y2="12" />
                  <line x1="12" y1="8" x2="12" y2="8" />
                </svg>
              </button>
            </div>
            <div
              v-if="showPasswordInstructions"
              class="mt-2 text-sm text-gray-600"
            >
              Password must be at least 8 characters long and include at least:
              <ul class="list-disc pl-5 mt-1">
                <li>One uppercase letter</li>
                <li>One lowercase letter</li>
                <li>One number</li>
                <li>One special character (e.g., @, $, !, %, #)</li>
              </ul>
            </div>
            <InputError class="mt-2" :message="errors.password" />
          </div>
          <div class="mt-4 text-sm text-gray-500">
            Fill in the details and start collaborating with the team you are
            invited in.
          </div>
          <div class="flex items-center justify-end mt-4">
            <PrimaryButton
              class="ms-4"
              :class="{ 'opacity-25': processing }"
              :disabled="processing"
            >
              Accept Invite
            </PrimaryButton>
          </div>
        </form>
      </AuthenticationCard>
      <div
        class="flex justify-center items-center h-screen text-lg md:text-2xl text-red-600 font-bold bg-gray-100 dark:bg-gray-800"
        v-else
      >
        Invitation link expired!
      </div>
    </div></transistion
  >
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AuthenticationCard from '@/components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/components/AuthenticationCardLogo.vue';
import InputError from '@/components/InputError.vue';
import InputLabel from '@/components/InputLabel.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';
import TextInput from '@/components/TextInput.vue';
import { toast } from 'vue3-toastify';
import Api from '@/utils/ApiClient';
import { useAuthStore } from '@/stores/authStore';
import { LoaderCircle } from 'lucide-vue-next';
import { validatePassword, validateRequired } from '@/utils/validator';

const router = useRouter();
const firstName = ref('');
const lastName = ref('');
const password = ref('');
const invitationExpired = ref(false);
const processing = ref(false);
const loading = ref(true);
const errors = ref({});
const showPasswordInstructions = ref(false);
const route = useRoute();
const token = route.params.token;
const teamId = route.params.team_id;
const authStore = useAuthStore();
const validate = () => {
  let isValid = true;
  errors.value = {};

  errors.value.firstName = validateRequired(firstName.value, 'First Name');
  errors.value.lastName = validateRequired(lastName.value, 'Last Name');
  errors.value.password = validatePassword(password.value);
  isValid = Object.values(errors.value).every((error) => !error);
  return isValid;
};

const submit = async () => {
  processing.value = true;

  if (!validate()) {
    processing.value = false;
    return;
  }

  const apiData = {
    first_name: firstName.value,
    last_name: lastName.value,
    password: password.value,
  };

  try {
    const response = await Api.post(
      `/auth/teams/${teamId}/invitation/${token}`,
      apiData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 201) {
      const data = response?.data;
      authStore.login(data?.access_token);
      router.push({ name: 'Dashboard' }).then(() =>
        toast.success('Invite accepted successfully', {
          position: 'top-center',
          autoClose: 2000,
          transition: 'zoom',
        }),
      );
    }
  } catch (error) {
    toast.error(error.response?.data?.detail, {
      position: 'top-center',
      autoClose: 2000,
      transition: 'zoom',
    });
  } finally {
    processing.value = false;
    firstName.value = '';
    lastName.value = '';
    password.value = '';
  }
};
const checkInvitationStatus = async () => {
  try {
    const response = await Api.get(`/invitation/status/${token}`);
    if (response.data?.expired) {
      toast.error('Link is expired', {
        autoClose: 2000,
        onClose: () => {
          window.close();
        },
      });
      invitationExpired.value = response.data.expired;
    } else {
      invitationExpired.value = false;
    }
  } catch (error) {
  } finally {
    loading.value = false;
  }
};
onMounted(() => {
  if (token) {
    checkInvitationStatus();
  }
});
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
