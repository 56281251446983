import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/pages/Auth/Login.vue';
import ProfileShow from '@/pages/Profile/Show.vue';
import TeamConnections from '@/pages/Teams/TeamConnections.vue';
import TeamSettings from '../pages/Teams/TeamSettings.vue';
import Register from '@/pages/Auth/Register.vue';
import WelcomeView from '@/views/WelcomeView.vue';
import VerifyEmailOtp from '@/pages/Auth/VerifyEmailOtp.vue';
import { useAuthStore } from '@/stores/authStore';
import DashBoard from '@/views/DashBoardView.vue';
import InsightView from '@/views/InsightView.vue';
import ForgotPassword from '@/pages/Auth/ForgotPassword.vue';
import NotFound from '@/views/NotFound.vue';
import GoogleAdsCallBackCard from '@/components/GoogleAdsCallBackCard.vue';
import GoogleAnalyticsCallBackCard from '@/components/GoogleAnalyticsCallBackCard.vue';
import GoogleSearchConsoleCallBackCard from '@/components/GoogleSearchConsoleCallBackCard.vue';
import FileConnectorView from '@/views/FileConnectorView.vue';
import Create from '@/pages/Teams/Create.vue';
import TeamKeywords from '@/pages/Teams/TeamKeywords.vue';
import InvitedRegister from '@/pages/Auth/InvitedRegister.vue';
import JoinTeam from '@/views/JoinTeam.vue';
import NavBarLayout from '@/layouts/NavBarLayout.vue';
import PrivacyPolicy from '../pages/PrivacyPolicy.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: WelcomeView,
      meta: {
        title: 'Home | DVIO ONE',
        requiresGuest: true,
      },
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicy,
      meta: {
        title: 'Privacy Policy | DViO One',
        requiresAuth: false,
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Login | DVIO ONE',
        requiresGuest: true,
      },
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        title: 'Register | DVIO ONE',
        requiresGuest: true,
      },
    },
    {
      path: '/verify',
      name: 'Verify',
      component: VerifyEmailOtp,
      meta: {
        title: 'Verify Yourself | DVIO ONE',
        requiresGuest: true,
      },
    },
    {
      path: '/teams/:team_id/invitations/:token',
      name: 'InvitedSignup',
      component: InvitedRegister,
      meta: { requiresAuth: false, title: 'Invitation | DVIO ONE' },
    },
    {
      path: '/forgot-password',
      name: 'Forgot Password',
      component: ForgotPassword,
      meta: {
        title: 'Reset Password | DVIO ONE',
        requiresGuest: true,
      },
    },

    {
      path: '/',
      component: NavBarLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: '/join-team/:team_id/invitations/:token',
          name: 'AcceptInvite',
          component: JoinTeam,
          meta: { requiresAuth: true, title: 'Join Team | DVIO ONE' },
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: DashBoard,
          meta: { requiresAuth: true, title: 'Dashboard | DVIO ONE' },
        },
        {
          path: 'insight',
          name: 'Insight',
          component: InsightView,
          meta: { requiresAuth: true, title: 'Insight | DVIO ONE' },
        },
        {
          path: 'settings/profile',
          name: 'Profile',
          component: ProfileShow,
          meta: { requiresAuth: true, title: 'User Profile | DVIO ONE' },
        },
        {
          path: 'settings/team/create',
          name: 'TeamCreate',
          component: Create,
          meta: { requiresAuth: true, title: 'Add new Team | DVIO ONE' },
        },
        {
          path: 'settings/team/manage',
          name: 'TeamManagement',
          component: TeamSettings,
          meta: { requiresAuth: true, title: 'Team Management | DVIO ONE' },
        },
        {
          path: '/settings/team/connections',
          name: 'TeamConnections',
          component: TeamConnections,
          meta: { requiresAuth: true, title: 'Team Connections | DVIO ONE' },
        },
        {
          path: '/settings/team/keywords',
          name: 'TeamKeywords',
          component: TeamKeywords,
          meta: { requiresAuth: true, title: 'Team Keywords | DVIO ONE' },
        },
        {
          path: '/connector/google-ads',
          name: 'Google Ads Connector',
          component: GoogleAdsCallBackCard,
          meta: {
            title: 'Google Ads Connector | DVIO ONE',
            requiresAuth: true,
          },
        },
        {
          path: '/connector/google-analytics-data-api',
          name: 'Google Analytics Connector',
          component: GoogleAnalyticsCallBackCard,
          meta: {
            title: 'Google Analytics Connector | DVIO ONE',
            requiresAuth: true,
          },
        },
        {
          path: '/connector/google-search-console',
          name: 'Google Search Console Connector',
          component: GoogleSearchConsoleCallBackCard,
          meta: {
            title: 'Google Search Console Connector | DVIO ONE',
            requiresAuth: true,
          },
        },
        {
          path: '/connector',
          name: 'Custom Connector',
          component: FileConnectorView,
          meta: {
            title: 'Custom Connector | DVIO ONE',
            requiresAuth: true,
          },
        },
      ],
    },

    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFound,
      meta: {
        title: '404 Not Found | DVIO ONE',
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  await authStore.checkAuth();

  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    return next({
      name: 'Login',
      query: { redirect: to.fullPath },
    });
  }

  if (to.meta.requiresGuest && authStore.isAuthenticated) {
    return next({ name: 'Dashboard' });
  }

  document.title = to.meta.title || 'DVIO ONE';
  next();
});

export default router;
