<script setup>
import { onMounted, ref, watch } from 'vue';
import PageHeading from '@/components/PageHeading.vue';
import AddTeamMember from './Partials/AddTeamMember.vue';
import PendingTeamInvitation from './Partials/PendingTeamInvitation.vue';
import { CircleDashed, Cog, UserRoundPlus, UsersRound } from 'lucide-vue-next';
import TeamMemberManager from './Partials/TeamMemberManager.vue';
import UpdateTeamNameForm from './Partials/UpdateTeamNameForm.vue';
import { useTeamStore } from '@/stores/teamStore';

const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const activeTab = ref('team-details');
watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      currentTeam.value = newTeam;
      teamStore.fetchMembers();
    }
  },
);
onMounted(() => {
  if (currentTeam.value?.id) {
    teamStore.fetchMembers();
  }
});
</script>

<template>
  <div class="flex-1 bg-white dark:bg-black dark:text-white min-h-screen">
    <PageHeading title="Team Settings" />

    <!-- Tab Navigation -->
    <div class="py-6 px-4 md:px-6">
      <div class="border-b border-gray-200 dark:border-gray-700 mb-6">
        <div class="flex md:space-x-6">
          <!-- Team Details -->
          <button
            :class="[
              'py-2 px-4 text-xs md:text-base font-semibold  focus:outline-none flex flex-col md:flex-row gap-1  md:items-center ',
              activeTab === 'team-details'
                ? 'text-blue-600 dark:text-blue-400 border-b-2 border-blue-600 dark:border-blue-400'
                : 'text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400',
            ]"
            @click="activeTab = 'team-details'"
          >
            <span class="flex justify-center w-full md:w-fit"
              ><Cog :size="16" /></span
            >Team Details
          </button>
          <!-- Team Member Data Tab -->
          <button
            :class="[
              'py-2 px-4 text-sm md:text-base font-semibold  focus:outline-none flex flex-col md:flex-row gap-1  md:items-center ',
              activeTab === 'team-member-data'
                ? 'text-blue-600 dark:text-blue-400 border-b-2 border-blue-600 dark:border-blue-400'
                : 'text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400',
            ]"
            @click="activeTab = 'team-member-data'"
          >
            <span class="flex justify-center w-full md:w-fit"
              ><UsersRound :size="16" /></span
            >Team Members
          </button>
          <!-- Add Team Member Tab -->
          <button
            :class="[
              'py-2 px-4 text-sm md:text-base font-semibold  focus:outline-none flex flex-col md:flex-row gap-1 md:items-center ',
              activeTab === 'add-team-member'
                ? 'text-blue-600 dark:text-blue-400 border-b-2 border-blue-600 dark:border-blue-400'
                : 'text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400',
            ]"
            @click="activeTab = 'add-team-member'"
          >
            <span class="flex justify-center w-full md:w-fit">
              <UserRoundPlus :size="16"
            /></span>
            Add Team Member
          </button>

          <!-- Pending Invitations Tab -->
          <button
            :class="[
              'py-2 px-4 text-sm md:text-base font-semibold  focus:outline-none flex flex-col md:flex-row gap-1 md:items-center ',
              activeTab === 'pending-invitations'
                ? 'text-blue-600 dark:text-blue-400 border-b-2 border-blue-600 dark:border-blue-400'
                : 'text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400',
            ]"
            @click="activeTab = 'pending-invitations'"
          >
            <span class="flex justify-center w-full md:w-fit">
              <CircleDashed :size="16"
            /></span>
            Pending Invitations
          </button>
        </div>
      </div>
      <div v-if="activeTab === 'team-details'" class="mb-4">
        <UpdateTeamNameForm />
      </div>
      <div v-if="activeTab === 'add-team-member'" class="mb-4">
        <AddTeamMember />
      </div>

      <div v-if="activeTab === 'team-member-data'" class="mb-4">
        <TeamMemberManager />
      </div>

      <div v-if="activeTab === 'pending-invitations'" class="mb-4">
        <PendingTeamInvitation />
      </div>
    </div>
  </div>
</template>
