import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    userData: {},
  }),
  actions: {
    setUser(userData) {
      this.userData = userData;
    },

    clearUser() {
      this.userData = null;
    },
  },
});
