<template>
  <div
    class="grid grid-rows-12 rounded-lg border bg-white dark:bg-gray-950 overflow-hidden px-4 pt-4 dark:border-gray-700 w-full min-h-full max-h-[420px] md:max-h-[500px]"
  >
    <!-- Header Section -->
    <div
      class="flex justify-between items-center flex-wrap row-span-3 md:row-span-2"
    >
      <div class="text-lg md:text-xl font-semibold dark:text-white">
        {{ title }}
      </div>
      <div
        class="flex items-center text-xs md:text-sm text-gray-500 dark:text-gray-300 gap-1"
      >
        <Calendar :size="16" />
        <span>{{ formattedStartDate }} - {{ formattedEndDate }}</span>
      </div>
    </div>
    <div
      v-if="error"
      class="flex justify-center items-center text-sm md:text-base font-roboto row-span-7 md:row-span-8"
    >
      No data
    </div>
    <!-- Table Headers -->
    <div v-else-if="!loading" class="row-span-7 md:row-span-8">
      <div>
        <div
          class="grid grid-cols-12 gap-2 py-2 border-b dark:border-gray-500 text-sm text-gray-700 dark:text-gray-300"
        >
          <div class="col-span-4 truncate">{{ column1 }}</div>
          <div class="col-span-4 truncate">{{ column2 }}</div>
          <div class="col-span-4 text-center truncate">{{ column3 }}</div>
        </div>

        <!-- Table Rows -->
        <div
          v-for="(item, index) in displayedData"
          :key="index"
          class="grid grid-cols-12 gap-2 items-center py-2 text-xs md:text-sm"
        >
          <!-- First Column -->
          <div class="col-span-4 truncate dark:text-white">
            {{ item[Number(displayIndex[0])] }}
          </div>

          <!-- Progress Bar Column -->
          <div class="col-span-4 flex items-center gap-2">
            <div class="w-1/5">{{ progressForItem(item).toFixed(0) }}%</div>
            <div
              class="relative w-4/5 h-4 bg-gray-200 dark:bg-gray-800 rounded-md overflow-hidden"
            >
              <div
                class="absolute h-full bg-amber-500 transition-all duration-300"
                :style="{ width: progressForItem(item) + '%' }"
              ></div>
            </div>
          </div>

          <!-- Change Percentage Column -->
          <div
            v-if="change"
            class="col-span-4 flex items-center justify-around dark:text-white"
          >
            <div class="flex items-center space-x-2">
              <span class="truncate">
                {{
                  formatter
                    ? formatter(item[Number(displayIndex[1])])
                    : item[Number(displayIndex[1])]
                }}
              </span>
              <div
                v-if="item[change] > 0"
                class="flex items-center text-green-500"
              >
                <ArrowUp size="16" />{{ item[change]?.toFixed(1) }}%
              </div>
              <div v-else class="flex items-center text-red-500">
                <ArrowDown size="16" />{{ item[change]?.toFixed(1) }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loader -->
    <div
      v-else
      class="flex justify-center items-center py-4 row-span-7 md:row-span-8"
    >
      <StyledLoader />
    </div>

    <!-- Pagination Controls -->
    <div
      class="flex justify-between items-center w-full space-x-4 pr-4 sticky bottom-0 bg-white dark:bg-gray-950 border-t dark:border-gray-500 row-span-2"
    >
      <!-- Data source label -->
      <div class="text-sm flex gap-1" v-if="srcName == 'gsc'">
        <img
          src="@/assets/google-search-console.png"
          alt="Google Search Console"
          class="h-4 w-auto"
        />Google Search Console
      </div>
      <div class="text-xs flex gap-1 font-medium" v-if="srcName == 'gads'">
        <img
          src="@/assets/google-ads.png"
          alt="Google Ads"
          class="h-4 w-auto"
        />Google Ads
      </div>
      <div class="text-xs flex gap-1 font-medium" v-if="srcName == 'gstats'">
        <img
          src="@/assets/google_analytics_4.png"
          alt="Google Analytics"
          class="h-3 w-auto"
        />Google Analytics
      </div>
      <div>
        <button
          @click="prevPage"
          v-if="currentPage !== 1"
          class="text-blue-500 text-xs md:text-sm dark:text-amber-500"
        >
          Previous
        </button>
        <button
          @click="nextPage"
          v-if="currentPage !== totalPages"
          class="text-blue-500 text-xs md:text-sm dark:text-amber-500"
        >
          See more
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTeamStore } from '@/stores/teamStore';
import Api from '@/utils/ApiClient';
import StyledLoader from '@/components/StyledLoader.vue';
import { ArrowDown, ArrowUp, Calendar } from 'lucide-vue-next';
import { ref, computed, watch, nextTick } from 'vue';
import { useDateStore } from '@/stores/dateStore';
const teamStore = useTeamStore();
const loading = ref(false);
const currentTeam = ref(teamStore.current_team);
const dateStore = useDateStore();
const error = ref(false);
const props = defineProps({
  title: String,
  apiUrl: String,
  column1: String,
  column2: String,
  column3: String,
  formatter: Function,
  displayIndex: Array,
  change: Number,
  srcName: String,
});

const itemsPerPage = 5;
const currentPage = ref(1);
const apiData = ref([]);
const fetchData = async () => {
  try {
    loading.value = true;

    const response = await Api.get(props.apiUrl, {
      params: {
        start_date: dateStore?.insightDate?.currentStart,
        end_date: dateStore?.insightDate?.currentEnd,
        past_start_date: dateStore.insightDate.pastStart,
        past_end_date: dateStore.insightDate.pastEnd,
      },
    });
    const data = JSON.parse(response.data?.items?.data);
    if (data) {
      apiData.value = data;
    }
    error.value = false;
  } catch (errorData) {
    error.value = true;
    apiData.value = [];
  } finally {
    loading.value = false;
  }
};

const totalPages = computed(() =>
  Math.ceil(apiData.value?.length / itemsPerPage),
);
const startIndex = computed(() => (currentPage.value - 1) * itemsPerPage);

const displayedData = computed(() =>
  apiData.value?.slice(startIndex.value, startIndex.value + itemsPerPage),
);

const totalSum = computed(() => {
  return apiData.value.reduce((sum, item) => {
    return sum + (item[Number(props.displayIndex[1])] || 0);
  }, 0);
});

const progressForItem = (item) => {
  const itemValue = item[Number(props.displayIndex[1])] || 0;
  return totalSum.value > 0 ? (itemValue / totalSum.value) * 100 : 0;
};
const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const formattedStartDate = computed(() => {
  const options = { year: 'numeric', month: 'short' };
  return new Date(dateStore?.insightDate?.pastStart).toLocaleDateString(
    'en-US',
    options,
  );
});

const formattedEndDate = computed(() => {
  const options = { year: 'numeric', month: 'short', year: 'numeric' };
  return new Date(dateStore?.insightDate?.currentEnd).toLocaleDateString(
    'en-US',
    options,
  );
});
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
    if (newTeam.id) {
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
watch(
  () => dateStore.insightDate,
  async () => {
    await nextTick();
    await fetchData();
  },
  { immediate: true, deep: true },
);
</script>
