export const validateEmail = (email) => {
  if (!email.trim()) {
    return 'Email is required.';
  }
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(email)) {
    return 'Invalid email address.';
  }
  return '';
};

export const validatePassword = (password) => {
  const passwordPattern =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  if (!passwordPattern.test(password)) {
    return 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.';
  }
  return '';
};

export const validateRequired = (value, fieldName) => {
  if (!value.trim()) {
    return `${fieldName} is required.`;
  }
  return '';
};

export const validatePasswordConfirmation = (password, confirmation) => {
  if (password !== confirmation) {
    return 'Passwords do not match.';
  }
  return '';
};
