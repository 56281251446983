<template>
  <section class="">
    <p class="pb-4 m-2 text-xs md:text-sm text-gray-500 dark:text-gray-400">
      Add a new team member to your team, allowing them to collaborate with you.
    </p>

    <form
      @submit.prevent="addTeamMember"
      class="bg-white dark:bg-gray-900 border border-gray-100 dark:border-gray-700 shadow sm:rounded-lg"
    >
      <div class="px-4 py-5 sm:px-6 grid gap-4">
        <div class="col-span-6 sm:col-span-4">
          <label
            for="email"
            class="block text-sm md:text-base font-medium text-gray-700 dark:text-gray-300 pb-2"
          >
            Email
          </label>
          <div class="text-xs md:text-sm text-gray-600 dark:text-gray-400 pb-2">
            Please provide the email address of the person you would like to add
            to this team.
          </div>
          <input
            id="email"
            v-model="addTeamMemberForm.email"
            type="email"
            class="w-full border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:text-gray-100 indent-2"
          />
          <span
            v-if="errors.email"
            class="text-red-500 text-xs md:text-sm mt-2 p-2 dark:text-red-400"
          >
            *{{ errors.email }}
          </span>
        </div>

        <div v-if="AVAILABLE_ROLES.length > 0" class="col-span-6 lg:col-span-4">
          <label
            for="roles"
            class="block text-sm font-medium text-gray-700 dark:text-gray-300"
          >
            Role
          </label>
          <div
            class="relative z-0 mt-1 border border-gray-200 dark:border-gray-700 rounded-lg cursor-pointer"
          >
            <button
              v-for="(role, i) in AVAILABLE_ROLES"
              :key="role.key"
              type="button"
              class="relative px-4 py-3 inline-flex w-full rounded-lg focus:z-10 focus:outline-none focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800"
              :class="{
                'border-t border-gray-200 dark:border-gray-700 rounded-t-none':
                  i > 0,
                'rounded-b-none': i !== AVAILABLE_ROLES.length - 1,
              }"
              @click="addTeamMemberForm.role = role.key"
            >
              <div
                :class="{
                  'opacity-50':
                    addTeamMemberForm.role &&
                    addTeamMemberForm.role !== role.key,
                }"
              >
                <div class="flex items-center">
                  <div
                    class="text-sm text-gray-600 dark:text-gray-300"
                    :class="{
                      'font-semibold': addTeamMemberForm.role === role.key,
                    }"
                  >
                    {{ role.name }}
                  </div>
                  <svg
                    v-if="addTeamMemberForm.role == role.key"
                    class="ms-2 h-5 w-5 text-green-400 dark:text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div class="mt-2 text-xs text-gray-600 dark:text-gray-400">
                  {{ role.description }}
                </div>
              </div>
            </button>
          </div>
          <span
            v-if="errors.role"
            class="text-red-500 text-xs md:text-sm mt-2 p-2 dark:text-red-400"
          >
            *{{ errors.role }}
          </span>
        </div>
      </div>

      <div class="px-4 py-3 text-right sm:px-6">
        <button
          type="submit"
          :class="{ 'opacity-25': addTeamMemberForm.processing }"
          :disabled="addTeamMemberForm.processing"
          class="inline-flex items-center px-4 py-2 bg-gray-800 dark:bg-gray-600 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900 dark:hover:bg-slate-800 dark:focus:bg-slate-600 dark:active:bg-slate-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:opacity-50 transition ease-in-out duration-150"
        >
          Add
        </button>
      </div>
    </form>
  </section>
</template>

<script setup>
import { useTeamStore } from '@/stores/teamStore';
import { AVAILABLE_ROLES } from '@/utils/constantData';
import Api from '@/utils/ApiClient';
import { ref, watch } from 'vue';
import { toast } from 'vue3-toastify';

const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const errors = ref({
  email: '',
  role: '',
});
const addTeamMemberForm = ref({
  email: '',
  role: null,
  processing: false,
});
const validate = () => {
  let isValid = true;

  if (!addTeamMemberForm.value.email.trim()) {
    errors.value.email = 'Email is required';
    isValid = false;
  } else {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(addTeamMemberForm.value.email)) {
      errors.value.email = 'Invalid email address.';
      isValid = false;
    }
  }
  if (!addTeamMemberForm.value.role) {
    errors.value.role = 'Please select a role';
    isValid = false;
  }

  return isValid;
};

const addTeamMember = async () => {
  try {
    addTeamMemberForm.value.processing = true;

    if (!validate()) {
      addTeamMemberForm.value.processing = false;
      return;
    }
    const response = await Api.post('/invitation', {
      team_id: currentTeam.value?.id,
      email: addTeamMemberForm.value.email,
      role: addTeamMemberForm.value.role,
    });
    if (response.data) {
      toast.success(response.data);
      addTeamMemberForm.value = {
        email: '',
        role: null,
        processing: false,
      };
      errors.value = {
        email: '',
        role: '',
      };
    }
  } catch (error) {
    toast.error(error.response.data.detail);
  } finally {
    addTeamMemberForm.value = {
      email: '',
      role: null,
      processing: false,
    };
  }
};
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
