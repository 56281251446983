<template>
  <div class="flex-1 dark:bg-black bg-gray-100 min-h-lvh">
    <div v-if="name == 'Blinkit'">
      <BlinkitUploadCard />
    </div>
  </div>
</template>
<script setup>
import BlinkitUploadCard from '@/components/BlinkitUploadCard.vue';
import { useRoute } from 'vue-router';
const route = useRoute();
const name = route.query.name;
</script>
