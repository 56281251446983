<template>
  <MetricCard
    apiEndpoint="/google-ads/spends"
    metricName="TOTAL SPENT"
    :formatData="formatCost"
    srcName="Google Ads"
  />
</template>

<script setup>
import { formatNumber } from '@/utils/helperFunctions';
import MetricCard from '../MetricCard.vue';

const formatCost = (data) => '₹' + formatNumber(data.data);
</script>
