<template>
  <div
    class="bg-gray-100 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 rounded-xl shadow-lg text-black dark:text-white w-full p-4"
  >
    <div class="text-lg font-semibold mb-2 text-gray-800 dark:text-white">
      Past Upload History:
    </div>
    <div class="grid">
      <div
        class="mt-4 overflow-x-auto border border-gray-200 rounded dark:border-gray-600 dark:text-white custom-scrollbar"
      >
        <table
          class="min-w-full bg-white divide-y divide-gray-200 dark:bg-slate-800 dark:divide-gray-800"
        >
          <thead>
            <tr class="bg-gray-50">
              <th
                v-for="column in pastUploadTableColumns"
                :key="column"
                class="px-3 py-2 text-xs font-medium text-white uppercase tracking-wider bg-blue-400 text-center"
              >
                {{ column }}
              </th>
            </tr>
          </thead>

          <tbody
            class="bg-white divide-y divide-gray-100 dark:bg-slate-800 dark:divide-gray-700"
          >
            <tr
              v-for="(row, index) in displayData"
              :key="index"
              class="hover:bg-gray-50 dark:hover:bg-slate-900 text-center"
            >
              <td class="px-3 py-2 text-sm text-gray-800 dark:text-gray-300">
                {{ row.id }}
              </td>
              <td
                class="px-3 py-2 text-sm text-gray-800 dark:text-gray-300 truncate"
              >
                {{ row.file_name }}
              </td>
              <td class="px-3 py-2 text-sm text-gray-800 dark:text-gray-300">
                {{ row.file_type }}
              </td>
              <td class="px-3 py-2 text-sm text-gray-800 dark:text-gray-300">
                {{ row.uploaded_by }}
              </td>
              <td class="px-3 py-2 text-sm text-gray-800 dark:text-gray-300">
                {{ row.upload_date }}
              </td>
              <td class="px-3 py-2 text-sm text-gray-800 dark:text-gray-300">
                <button
                  @click="openConfirmationModal(row.id)"
                  :disabled="loading"
                  class="text-red-500 hover:text-red-600 font-medium"
                >
                  <span
                    v-if="loading && uploadIdToDelete === row.batch"
                    class=""
                  >
                    <Loader
                  /></span>
                  <span
                    v-else
                    class="flex items-center gap-2 justify-center w-full"
                    >Revert this upload <Undo2
                  /></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="!displayData.length"
          class="text-center py-6 text-gray-500 dark:text-gray-400 text-sm"
        >
          No records found.
        </div>
      </div>
    </div>

    <ConfirmationModal
      :show="showConfirmationModal"
      @close="showConfirmationModal = false"
    >
      <template #title>Confirm Deletion</template>
      <template #content>
        Are you sure you want to delete this entry? This action cannot be
        undone.
      </template>
      <template #footer>
        <button
          class="bg-gray-200 text-gray-700 px-4 py-2 mr-2 rounded"
          @click="showConfirmationModal = false"
        >
          Cancel
        </button>
        <button
          class="bg-red-600 text-white px-4 py-2 rounded"
          :disabled="loading"
          @click="confirmDelete"
        >
          <span v-if="loading">Deleting...</span>
          <span v-else>Confirm</span>
        </button>
      </template>
    </ConfirmationModal>
  </div>
</template>

<script setup>
import { useTeamStore } from '@/stores/teamStore';
import Api from '@/utils/ApiClient';
import { ref, watch } from 'vue';
import ConfirmationModal from './ConfirmationModal.vue';
import { Loader, Undo2 } from 'lucide-vue-next';
import { toast } from 'vue3-toastify';
import { useConnectorStore } from '@/stores/connectorStore';

const teamStore = useTeamStore();
const connectorStore = useConnectorStore();
const currentTeam = ref(teamStore.current_team);
const props = defineProps(['data']);
const displayData = ref([]);
const pastUploadTableColumns = [
  'ID.',
  'Filename',
  'File Type',
  'Uploaded By',
  'Upload Date',
  'Action',
];

const showConfirmationModal = ref(false);
const uploadIdToDelete = ref(null);
const filetypeToDelete = ref(null);
const loading = ref(false);

const fetchPastData = async () => {
  try {
    const response = await Api.get(`/${props.data.sourceType}`);

    if (response.data) {
      displayData.value = response.data.upload_details;
    }
  } catch (error) {
    displayData.value = [];
  } finally {
    connectorStore.setRecentFetchPending();
  }
};

const openConfirmationModal = (uploadID) => {
  uploadIdToDelete.value = uploadID;
  showConfirmationModal.value = true;
};

const confirmDelete = async () => {
  loading.value = true;

  try {
    const response = await Api.delete(
      `/${props.data.sourceType}/blinkit-imports/${uploadIdToDelete.value}`,
    );
    if (response.data) {
      toast.success(response.data.message);
      connectorStore.setRecentFetchPending();
    }
  } catch (error) {
    console.error('Error deleting the entry:', error);
    toast.error('Failed to revert');
  } finally {
    loading.value = false;
    showConfirmationModal.value = false;
    uploadIdToDelete.value = null;
    filetypeToDelete.value = null;
  }
};

watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
    if (newTeam?.id) {
      fetchPastData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
watch(
  () => connectorStore.recentFetchPending,
  (newData) => {
    if (newData) {
      fetchPastData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
