import axios from 'axios';
import { clearSession } from './helperFunctions';

const url = import.meta.env.VITE_BACKEND_URL;

const getParsedLocalStorageItem = (key) => {
  const rawValue = localStorage.getItem(key);
  try {
    return JSON.parse(rawValue);
  } catch {
    localStorage.removeItem(key);
    return null;
  }
};

const Api = axios.create({
  baseURL: `${url}/api`,
});

Api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access_token');
    const currentTeam = getParsedLocalStorageItem('current_team');

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (currentTeam?.id) {
      config.headers.team_id = currentTeam.id;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        clearSession();
        window.location.href = '/login';
      } catch (logoutError) {
        console.error('Logout failed in Axios interceptor:', logoutError);
      }
    }
    return Promise.reject(error);
  },
);

export default Api;
