import './assets/main.css';
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import VueApexCharts from 'vue3-apexcharts';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import VueCookies from 'vue3-cookies';
import App from './App.vue';
import router from './router';
import { useCookies } from 'vue3-cookies';
import * as Sentry from '@sentry/vue';
import 'vue-multiselect/dist/vue-multiselect.css';
import Clarity from '@microsoft/clarity';

const app = createApp(App);

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [],
  });

  if (import.meta.env.VITE_CLARITY_ID) {
    Clarity.init(import.meta.env.VITE_CLARITY_ID);
  }
}

app.use(createPinia());
app.use(router);
app.use(Vue3Toastify, {
  autoClose: 2000,
});
app.use(VueCookies, {
  path: '/',
  secure: true,
});
app.use(useCookies);
app.use(VueApexCharts);
app.mount('#app');
