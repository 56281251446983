<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-2 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full flex-grow"
  >
    <div class="text-base md:text-lg">Session Medium</div>
    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <div v-else class="flex-1 grid">
      <div
        class="flex justify-center items-center flex-col lg:flex-row"
        v-if="data.length"
      >
        <apexchart
          type="donut"
          :options="chartOptions"
          :series="chartSeries"
          class=""
        ></apexchart>
        <div
          class="text-sm flex lg:flex-col gap-2 md:gap-0 flex-wrap justify-center"
        >
          <span
            v-for="(item, index) in labelData"
            :key="index"
            :style="{ color: colors[index] }"
          >
            ● {{ item }}
          </span>
        </div>
      </div>
      <div v-else class="flex justify-center items-center text-base md:text-lg">
        No data. Try Again!
      </div>
    </div>
    <div class="text-left text-base">Google Analytics</div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import { formatNumber } from '@/utils/helperFunctions';
import { useDateStore } from '@/stores/dateStore';
import StyledLoader from '@/components/StyledLoader.vue';

const teamStore = useTeamStore();
const dateStore = useDateStore();
const currentTeam = ref(teamStore.current_team);
const data = ref([]);
const loading = ref(false);
const labelData = ref([]);
const chartSeries = ref([]);
const chartLabels = ref([]);
const colors = ['#fbbf24', '#f59e0b', '#34d399', '#60a5fa', '#e11d48'];
const chartOptions = computed(() => ({
  chart: {
    id: 'sessions-chart',
    type: 'donut',

    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },

  colors: colors,

  labels: chartLabels.value,
  legend: {
    show: false,
  },
  tooltip: {
    theme: 'dark',
  },
  noData: {
    text: 'Try Again',
  },
  dataLabels: {
    enabled: false,
  },
}));
const fetchData = async () => {
  try {
    loading.value = true;
    const response = await Api.get(`/google-analytics/session-medium`, {
      params: {
        start_date: dateStore?.dashboardDate.currentStart,
        end_date: dateStore?.dashboardDate.currentEnd,
      },
    });

    data.value = JSON.parse(response.data?.items.data);
  } catch (error) {
    data.value = [];
    chartLabels.value = [];
    chartSeries.value = [];
    labelData.value = [];
  } finally {
    loading.value = false;
  }
};

watch(
  () => dateStore.dashboardDate,
  async () => {
    await fetchData();
  },
  { immediate: true, deep: true },
);

watch(
  () => data?.value,
  (newData) => {
    if (Array.isArray(newData)) {
      const labels = newData.map((items) => items[0]);
      const series = newData.map((item) => item[1]);
      labelData.value = newData.map(
        (item) => item[0].toUpperCase() + '-' + formatNumber(item[1]),
      );

      chartLabels.value = labels;
      chartSeries.value = series;
    }
  },
  { immediate: true, deep: true },
);
watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      currentTeam.value = newTeam;
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
