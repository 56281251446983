import { calculateDate } from '@/utils/helperFunctions';
import { defineStore } from 'pinia';

const initialInsightDate = {
  currentStart: calculateDate(-31),
  currentEnd: calculateDate(-1),
  pastStart: calculateDate(-62),
  pastEnd: calculateDate(-32),
};
const initialDashboardDate = {
  currentStart: calculateDate(-8),
  currentEnd: calculateDate(-1),
};
export const useDateStore = defineStore('date', {
  state: () => ({
    insightDate: initialInsightDate,
    dashboardDate: initialDashboardDate,
  }),
  actions: {
    setInsightDate(name, value) {
      this.insightDate[name] = value;
    },
    setDashboardDate(name, value) {
      this.dashboardDate[name] = value;
    },
    clear() {
      this.insightDate = initialInsightDate;
      this.dashboardDate = initialDashboardDate;
    },
  },
});
