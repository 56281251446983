import { clearSession } from '@/utils/helperFunctions';
import axios from 'axios';
import { defineStore } from 'pinia';

const ACCESS_TOKEN_KEY = 'access_token';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
  }),
  actions: {
    login(accessToken) {
      this.isAuthenticated = true;
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    },
    logout() {
      this.isAuthenticated = false;
      clearSession();
      window.location.href = '/login';
    },
    async checkAuth() {
      const token = localStorage.getItem(ACCESS_TOKEN_KEY);
      if (!token) {
        this.isAuthenticated = false;
        return;
      }
      try {
        const response = await axios.get('/settings', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.isAuthenticated = response.status === 200;
      } catch (error) {
        this.logout();
      }
    },
  },
});
