import { defineStore } from 'pinia';

const initialCompletedState = {
  'google-ads': false,
  'google-analytics-data-api': false,
  'facebook-ads': false,
  'google-search-console': false,
  'amazon-ads': false,
};
const initialId = {
  'google-ads': '',
  'google-analytics-data-api': '',
  'facebook-ads': '',
  'google-search-console': '',
  'amazon-ads': '',
};
export const useConnectorStore = defineStore('connector', {
  state: () => ({
    loading: false,
    recentFetchPending: false,
    completedConnectors: initialCompletedState,
    connectorId: initialId,
  }),
  actions: {
    setLoading() {
      this.loading = !this.loading;
    },
    setRecentFetchPending() {
      this.recentFetchPending = !this.recentFetchPending;
    },
    setCompleted(name, status) {
      this.completedConnectors[name] = status;
    },
    setConnectorId(name, id) {
      this.connectorId[name] = id;
    },

    clearCompleted() {
      this.completedConnectors = {
        'google-ads': false,
        'google-analytics-data-api': false,
        'facebook-ads': false,
        'google-search-console': false,
        'amazon-ads': false,
      };
    },
    clearId() {
      this.connectorId = {
        'google-ads': '',
        'google-analytics-data-api': '',
        'facebook-ads': '',
        'google-search-console': '',
        'amazon-ads': '',
      };
    },

    clearStore() {
      this.loading = false;
      this.recentFetchPending = false;
      this.completedConnectors = initialCompletedState;
      this.connectorId = initialId;
    },
  },
});
