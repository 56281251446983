<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-2 text-center px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 h-full"
  >
    <!-- Header section  and title -->
    <div>{{ currentMonth }}</div>
    <div class="flex items-center justify-around text-base md:text-lg">
      <div class="">
        {{ name }}
        <div class="text-lg md:text-xl font-medium">
          <span> {{ growth }} </span>
        </div>
      </div>
      <div>
        {{ secondName }}
        <div class="text-lg md:text-xl font-medium">
          <span> {{ secondGrowth }} </span>
        </div>
      </div>
    </div>
    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <div v-else class="flex-1 grid">
      <div
        class="my-2 mx-auto flex-grow flex justify-center items-center"
        v-if="data.length"
      >
        <apexchart
          type="line"
          :options="chartOptions"
          :series="chartSeries"
          class="flex-1"
        ></apexchart>
      </div>
      <div v-else class="flex justify-center items-center text-base md:text-lg">
        No data. Try Again!
      </div>
    </div>
    <div class="flex justify-between">
      <span>{{ dataSrc }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import StyledLoader from '@/components/StyledLoader.vue';

const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const dataSrc = 'Google Ads';
const name = 'CPC';
const currentMonth = ref();
const secondName = 'CTR';
const growth = ref('');
const secondGrowth = ref('');
const categories = ref([]);
const data = ref([]);
const loading = ref(false);
const chartSeries = ref([
  {
    name: 'CPC',
    data: [],
  },
  {
    name: 'CTR',
    data: [],
  },
]);

const fetchData = async () => {
  try {
    loading.value = true;
    const response = await Api.get(`/google-ads/cpc-and-ctr-metrics`);
    data.value = JSON.parse(response.data?.items.data);
  } catch (error) {
    data.value = [];
  } finally {
    loading.value = false;
  }
};

watch(
  () => data.value,
  (newData) => {
    if (newData) {
      if (newData.length == 0) {
        categories.value = [];
        currentMonth.value = 'Try Again';
        growth.value = '';
        secondGrowth.value = '';
        chartSeries.value = [
          {
            name: 'CPC',
            data: [],
          },
          {
            name: 'CTR',
            data: [],
          },
        ];
      }
    }

    if (Array.isArray(newData) && newData.length > 0) {
      categories.value = newData.map((item) => {
        const temp = new Date(item[0]);
        return temp.toLocaleDateString('en-GB', {
          month: 'short',
          year: 'numeric',
        });
      });

      currentMonth.value = categories.value[categories.value.length - 1];

      const cpc = newData.map((item) => item[1].toFixed(2));
      const ctr = newData.map((item) => item[2].toFixed(2));

      if (cpc.length > 0) {
        growth.value = '₹' + parseFloat(cpc[cpc.length - 1]).toFixed(2);
      }

      if (ctr.length > 0) {
        secondGrowth.value = parseFloat(ctr[ctr.length - 1]).toFixed(2) + '%';
      }

      chartSeries.value = [
        {
          name: 'CPC',
          data: cpc,
        },
        {
          name: 'CTR',
          data: ctr,
        },
      ];
    }
  },
  { immediate: true, deep: true },
);
const chartOptions = computed(() => ({
  chart: {
    id: 'ctr-and-cpc-chart',
    type: 'line',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  colors: ['#3b82f6', '#a855f7'],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    categories: categories.value,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  grid: {
    show: false,
  },
  tooltip: {
    x: {
      formatter: function (value, { seriesIndex, dataPointIndex }) {
        const category = categories.value[dataPointIndex];
        return `${category}`;
      },
    },
    theme: 'dark',
  },
  noData: {
    text: 'No Data',
  },
  legend: {
    position: 'bottom',

    labels: {
      useSeriesColors: true,
    },
  },
}));
watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      currentTeam.value = newTeam;
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
