<!-- src/components/LandingPageTable.vue -->
<template>
  <InsightCard
    title="Top performing pages "
    apiUrl="/google-analytics-insights/top-landing-page-metrics"
    :formatter="formatNumber"
    :displayIndex="index"
    :change="3"
    column1="Path"
    column2="Share"
    column3="Users"
    srcName="gstats"
  />
</template>

<script setup>
import { formatNumber } from '@/utils/helperFunctions';
import InsightCard from '../InsightCard.vue';
const index = [0, 1];
</script>
