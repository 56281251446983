<template>
  <MetricCard
    apiEndpoint="/google-search-console/click-through-rate"
    metricName="CTR"
    :formatData="formatCTR"
    srcName="Google Search Console"
  />
</template>

<script setup>
import MetricCard from '../MetricCard.vue';

const formatCTR = (data) => data.data.toFixed(2) + '%';
</script>
