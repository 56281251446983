<template>
  <InsightCard
    title="Top Cities based on traffic"
    column1="City"
    column2="Share"
    column3="Users"
    apiUrl="/google-analytics-insights/top-cities-by-user-growth-metrics"
    :displayIndex="index"
    :formatter="formatNumber"
    :change="4"
    srcName="gstats"
  />
</template>

<script setup>
import { formatNumber } from '@/utils/helperFunctions';
import InsightCard from '../InsightCard.vue';

const index = [0, 1];
</script>
