<template>
  <div class="max-w-4xl mx-auto px-4 py-8">
    <h1 class="text-2xl font-bold mb-6">DViO One Privacy Policy</h1>
    <div class="prose prose-sm md:prose-base" v-html="renderedContent"></div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { marked } from 'marked';

const privacyPolicyContent = `
**Effective Date:** December 1, 2024

Welcome to DViO One ([dvio.one](https://dvio.one)), a data platform provided by DViO Digital ([dviodigital.com](https://www.dviodigital.com)). Your privacy is of utmost importance to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform. By accessing or using DViO One, you agree to the terms outlined here.

## 1. Information We Collect

### 1.1 Personal Information

We may collect personal information that you provide directly to us, such as:

- **Account Information:** When you register, we collect your name, email address, organization, and other contact details.
- **Billing Information:** When you purchase our services, we collect billing details, such as your credit card information and billing address.
- **Marketing Platform Data:** We collect data from your marketing platforms, such as campaign performance metrics, user demographics, conversion rates, audience segmentation, and content performance. This information enables us to provide a holistic view of your marketing activities and generate actionable insights.

### 1.2 Data Integration Information

DViO One allows integration with third-party platforms like Google Ads, Analytics, Facebook, LinkedIn, Twitter, Instagram, and YouTube. To provide the core functionality of DViO One, we collect and process the following:

- **Account Authentication:** Access tokens, API keys, and credentials to connect with your third-party accounts.
- **Platform Metrics:** Data from your connected platforms, such as ad performance metrics, social media engagement, website analytics, and other marketing data.

### 1.3 Automatically Collected Information

We also collect information automatically when you interact with our platform, such as:

- **Usage Data:** IP address, device type, operating system, browser type, and platform usage statistics.
- **Cookies and Tracking Technologies:** We use cookies and similar technologies to enhance user experience and collect usage data.

## 2. How We Use Your Information

We use the information collected for purposes including, but not limited to:

- **Providing Services:** To provide and maintain our platform, facilitate data integrations, and ensure our services run smoothly.
- **Marketing Optimization and Insights:** Our AI uses the collected marketing data to generate insights that can help you improve marketing efficiency and optimize ad campaigns.
- **Customer Support:** To provide customer service, answer questions, and resolve issues.
- **Analytics and Improvement:** To monitor usage, identify trends, and make improvements to our platform.
- **Billing and Administration:** To manage payments and subscriptions.

## 3. Sharing Your Information

We do not sell your information. We may share your data in the following situations:

- **Service Providers:** We share data with third-party vendors who assist us in providing the platform, including cloud storage, payment processing, and customer support.
- **Compliance with Legal Obligations:** We may disclose information as required by law, such as to comply with a subpoena or other legal process.
- **Business Transfers:** If DViO Digital is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.

## 4. Security

We use a variety of organizational and technical measures to protect your information. Despite these safeguards, no data transmission or storage system is entirely secure. We encourage you to take precautions to protect your information.

## 5. Your Choices

- **Access and Update:** You may access and update your account information by logging into DViO One.
- **Opt-Out of Communications:** You can opt out of receiving marketing emails by following the unsubscribe instructions included in those emails.
- **Disconnect Integrations:** You can disconnect third-party data sources from DViO One at any time via the platform settings.

## 6. Data Retention

We retain your personal information as long as necessary to fulfill the purposes for which it was collected and to comply with legal obligations. Marketing data collected from third-party integrations will be retained for the duration of your subscription and may be deleted upon request.

## 7. Children’s Privacy

DViO One is not intended for children under the age of 16. We do not knowingly collect personal information from children under 16. If you believe we have collected such information, please contact us immediately.

## 8. International Data Transfers

Your information may be transferred to, and maintained on, servers located outside your jurisdiction. By using DViO One, you consent to such transfers.

## 9. Changes to This Privacy Policy

We may update this Privacy Policy from time to time. The most current version will always be available on our website, and we will notify you of any material changes via email or through our platform.

## 10. Contact Us

If you have any questions about this Privacy Policy, please contact us:

- **Email:** dvio1@dviodigital.com
- **Mail:** DViO Digital Private Limited, 10th Floor, Mont Vert Velocity, Baner - Pashan Link Road, Pashan, Pune, MH - 411021

Thank you for trusting DViO One. We are committed to protecting your privacy and ensuring your experience with us is both safe and effective.
`;

const renderedContent = ref('');

onMounted(() => {
  renderedContent.value = marked(privacyPolicyContent);
});
</script>
