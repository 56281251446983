<template>
  <MetricCard
    apiEndpoint="/google-ads/conversion-rate"
    metricName="CONVERSION RATE"
    :formatData="formatCR"
    srcName="Google Ads"
  />
</template>

<script setup>
import MetricCard from '../MetricCard.vue';

const formatCR = (data) => data.data.toFixed(2) + '%';
</script>
