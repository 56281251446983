<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-4 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full flex-grow max-h-[420px] md:max-h-[500px]"
  >
    <!-- Header section with title -->
    <div class="flex justify-between flex-wrap">
      <div class="text-base md:text-xl font-semibold mb-2 dark:text-white">
        Device Distribution
      </div>
      <div
        class="flex text-sm items-center text-gray-500 dark:text-gray-300 px-4 gap-1 mb-2"
      >
        <Calendar :size="16" />
        {{ formattedStartDate }} - {{ formattedEndDate }}
      </div>
    </div>
    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <div v-else class="flex-1 grid">
      <div
        v-if="data.length"
        class="flex justify-center items-center flex-col lg:flex-row"
      >
        <apexchart
          type="donut"
          :options="chartOptions"
          :series="chartSeries"
          class=""
        ></apexchart>
        <div
          class="text-xs md:text-base lg:text-lg flex lg:flex-col gap-2 md:gap-0 flex-wrap justify-center"
        >
          <span
            v-for="(item, index) in labelData"
            :key="index"
            :style="{ color: colors[index] }"
          >
            ● {{ item }}
          </span>
        </div>
      </div>
      <div v-else class="flex justify-center items-center text-base md:text-lg">
        No data. Try different dates
      </div>
    </div>
    <div class="flex gap-2 pt-1 text-xs font-medium">
      <img
        src="@/assets/google_analytics_4.png"
        alt="Google Analytics"
        class="h-3 w-auto"
      />Google Analytics
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import { formatNumber } from '@/utils/helperFunctions';
import { useDateStore } from '@/stores/dateStore';
import { Calendar } from 'lucide-vue-next';
import StyledLoader from '../StyledLoader.vue';

const teamStore = useTeamStore();
const dateStore = useDateStore();
const currentTeam = ref(teamStore.current_team);
const data = ref();
const labelData = ref();
const loading = ref(false);

const chartSeries = ref([]);
const chartLabels = ref([]);

const colors = ['#fbbf24', '#FF7F50', '#0000FF', '#60a5fa', '#f59e0b'];
const chartOptions = computed(() => ({
  chart: {
    id: 'device-chart',
    type: 'donut',

    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },

  colors: colors,

  labels: chartLabels.value,
  legend: {
    show: false,
  },
  tooltip: {
    theme: 'dark',
  },
  noData: {
    text: 'No data',
  },
  dataLabels: {
    enabled: false,
  },
}));
const formattedStartDate = computed(() => {
  const options = { year: 'numeric', month: 'short' };
  return new Date(dateStore?.insightDate?.pastStart).toLocaleDateString(
    'en-US',
    options,
  );
});

const formattedEndDate = computed(() => {
  const options = { year: 'numeric', month: 'short', year: 'numeric' };
  return new Date(dateStore?.insightDate?.currentEnd).toLocaleDateString(
    'en-US',
    options,
  );
});
const fetchData = async () => {
  try {
    loading.value = true;
    const response = await Api.get(
      `/google-analytics-insights/device-driving-traffic-trends-metrics`,
      {
        params: {
          start_date: dateStore.insightDate.currentStart,
          end_date: dateStore.insightDate.currentEnd,
          past_start_date: dateStore.insightDate.pastStart,
          past_end_date: dateStore.insightDate.pastEnd,
        },
      },
    );

    data.value = JSON.parse(response.data?.items.data);
  } catch (error) {
    data.value = [];
    chartLabels.value = [];
    chartSeries.value = [];
    labelData.value = [];
  } finally {
    loading.value = false;
  }
};

watch(
  () => dateStore.insightDate,
  async () => {
    await fetchData();
  },
  { immediate: true, deep: true },
);

watch(
  () => data?.value,
  (newData) => {
    if (Array.isArray(newData) && data?.value?.length > 0) {
      const labels = newData.map((items) => items[0]);
      const series = newData.map((item) => item[1]);
      labelData.value = newData.map(
        (item) => item[0].toUpperCase() + '-' + formatNumber(item[1]),
      );
      chartLabels.value = labels;
      chartSeries.value = series;
    }
  },
  { immediate: true, deep: true },
);
watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      currentTeam.value = newTeam;
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
