<template>
  <section class="">
    <p class="pb-4 m-2 text-xs md:text-sm text-gray-500 dark:text-gray-400">
      Edit the name of your team. Ensure that the details are accurate for your
      team members.
    </p>

    <form
      @submit.prevent="updateTeamName"
      class="bg-white dark:bg-gray-900 border border-gray-100 dark:border-gray-700 shadow sm:rounded-lg"
    >
      <div class="px-4 py-5 sm:px-6 grid gap-4">
        <!-- Team Owner Information -->
        <div class="col-span-6 sm:col-span-4">
          <label
            for="owner-info"
            class="block text-sm md:text-base font-medium text-gray-700 dark:text-gray-300 pb-2"
          >
            Team Owner
          </label>
          <div
            id="owner-info"
            class="w-full border grid md:grid-cols-2 gap-1 border-gray-300 dark:border-gray-600 rounded-md px-4 py-2 mt-2 dark:bg-gray-800 dark:text-gray-100"
          >
            <p
              v-if="owner"
              class="text-sm md:text-base text-gray-700 dark:text-gray-300"
            >
              <strong>Name:</strong> {{ owner.name }}
            </p>
            <p
              v-if="owner"
              class="text-sm md:text-base text-gray-700 dark:text-gray-300"
            >
              <strong>Email:</strong> {{ owner.email }}
            </p>
            <p v-else class="text-sm text-gray-500 dark:text-gray-400">
              Owner information is not available.
            </p>
          </div>
        </div>

        <!-- Team Name -->
        <div class="col-span-6 sm:col-span-4">
          <label
            for="name"
            class="block text-sm md:text-base font-medium text-gray-700 dark:text-gray-300 pb-2"
          >
            Team Name
          </label>
          <input
            id="name"
            v-model="form.name"
            type="text"
            class="w-full border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:text-gray-100 px-4 py-2 mt-2"
            placeholder="Enter team name"
          />
          <span
            v-if="form.errors.name"
            class="text-red-500 text-xs md:text-sm mt-2 p-2 dark:text-red-400"
          >
            *{{ form.errors.name }}
          </span>
        </div>
      </div>

      <!-- Action Buttons -->
      <div class="px-4 py-3 text-right sm:px-6">
        <button
          type="submit"
          :disabled="form.processing || form.name == current_team?.name"
          :class="{
            'opacity-25': form.processing,
          }"
          class="inline-flex items-center px-4 py-2 bg-gray-800 dark:bg-gray-600 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900 dark:hover:bg-slate-800 dark:focus:bg-slate-600 dark:active:bg-slate-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:opacity-50 transition ease-in-out duration-150"
        >
          <span
            v-if="form.processing"
            class="mr-2 spinner-border spinner-border-sm"
          ></span>
          Save Changes
        </button>
      </div>
    </form>
  </section>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useTeamStore } from '@/stores/teamStore';
import Api from '@/utils/ApiClient';
import { toast } from 'vue3-toastify';
import { storeToRefs } from 'pinia';

const teamStore = useTeamStore();
const { members, current_team } = storeToRefs(teamStore);

const form = ref({
  name: '',
  errors: {},
  processing: false,
});

const owner = ref(null);

watch(
  members,
  (newMembers) => {
    if (newMembers && Array.isArray(newMembers)) {
      const teamOwner = newMembers.find((member) => member.role === 'owner');
      owner.value = teamOwner || null;
    } else {
      owner.value = null;
    }
  },
  { immediate: true, deep: true },
);

const validate = () => {
  form.value.errors = {};
  let isValid = true;

  if (!form.value.name.trim()) {
    form.value.errors.name = 'Team name is required.';
    isValid = false;
  } else if (form.value.name.trim().length < 3) {
    form.value.errors.name = 'Team name must be at least 3 characters.';
    isValid = false;
  }

  return isValid;
};

const updateTeamName = async () => {
  if (!validate()) return;

  form.value.processing = true;

  try {
    const response = await Api.patch(`/team`, {
      team_name: form.value.name.trim(),
    });

    if (response.status === 200) {
      toast.success('Team name updated successfully.');
      teamStore.refetchNewTeamsFlag = true;
      form.value.errors = {};
    }
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error updating team name.');
  } finally {
    form.value.processing = false;
  }
};

watch(
  current_team,
  (newTeam) => {
    if (newTeam) {
      form.value.name = newTeam.name || '';
    }
  },
  { immediate: true },
);
</script>
