<template>
  <div
    class="rounded-lg dark:bg-black min-h-screen bg-gray-100 p-6 md:p-10 w-full h-full flex flex-col justify-center items-center shadow-lg space-y-6"
  >
    <div
      v-if="loading"
      class="bg flex flex-col justify-center items-center h-full w-full"
    >
      <div class="animate-spin"><Settings :size="40" /></div>

      <div class="text-lg font-semibold font-mono">Connecting your account</div>
    </div>
    <div v-else>
      <!-- Header with Google Analytics Logo -->
      <div class="flex items-center justify-center mb-6">
        <img
          src="@/assets/google_analytics_4.png"
          alt="Google Analytics Logo"
          class="h-8 w-8 mr-3"
        />
        <div
          v-if="connected"
          class="font-medium text-gray-800 dark:text-gray-100 text-center lg:text-lg text-base h-full w-full p-4"
        >
          Connected Successfully . Navigating you back .....
        </div>
        <div class="text-2xl font-bold text-gray-800 dark:text-gray-100" v-else>
          Choose Properties to Sync Data From
        </div>
      </div>
      <div v-if="!connected">
        <!-- Instruction Text -->
        <p class="text-gray-600 dark:text-gray-300 text-center mb-4">
          Please enter one or more properties from the list below to sync with
          Google Analytics. You can add and remove properties, then click
          "Connect Selected Properties" to proceed.
        </p>

        <!-- Input Fields for Property IDs -->
        <div
          v-for="(propertyId, index) in propertyIds"
          :key="index"
          class="flex items-center mb-4 space-x-3"
        >
          <input
            v-model="propertyIds[index]"
            type="text"
            placeholder="Enter property ID"
            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 dark:bg-gray-800 dark:text-white dark:border-gray-600"
          />
          <button
            @click="removePropertyId(index)"
            class="text-red-500 hover:text-red-700 font-semibold transition duration-200 ease-in-out"
          >
            <CircleMinus />
          </button>
        </div>

        <!-- Add More Property Button -->
        <div class="flex justify-center mb-4">
          <button
            @click="addNewPropertyId"
            class="text-blue-600 font-semibold hover:underline transition duration-200 ease-in-out flex gap-2"
          >
            <CirclePlus /> Add another property
          </button>
        </div>

        <!-- Connect Button -->
        <div class="flex justify-end mt-8">
          <button
            v-if="secretId"
            @click="establishConnection"
            :disabled="propertyIds.length == 0"
            class="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out"
          >
            Connect Selected Properties
          </button>
          <button
            v-else
            @click="continueConection"
            :disabled="propertyIds.length == 0"
            class="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out"
          >
            Continue Selecting Property ID's
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTeamStore } from '@/stores/teamStore';
import Api from '@/utils/ApiClient';
import { CircleMinus, CirclePlus, Settings } from 'lucide-vue-next';
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';

const secretId = ref();
const sourceType = 'google-analytics-data-api';
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const propertyIds = ref([]);
const connected = ref(false);

watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
    deep: true,
  },
);

const addNewPropertyId = () => {
  propertyIds.value.push('');
};

const removePropertyId = (index) => {
  propertyIds.value.splice(index, 1);
};
const validatePropertyIds = () => {
  return propertyIds.value.every((id) => {
    const idString = id.toString();
    return idString.length >= 8 && idString.length <= 12 && !isNaN(id);
  });
};
const establishConnection = async () => {
  try {
    loading.value = true;
    if (!validatePropertyIds()) {
      toast.error(
        'Please ensure all Property IDs are numbers and 8-12 digits long.',
      );
      return;
    }
    const payloadProperty = propertyIds.value.map((id) => id.toString());
    const response = await Api.post(
      `/airbyte/google-analytics`,
      {
        property_id: payloadProperty,
        secret_id: secretId.value,
      },
      {
        params: {
          source_type: sourceType,
        },
      },
    );

    if (response.status == 200) {
      toast.success('Successfully connected properties!', {
        onClose: () => {
          router.push('/settings/team/connections');
        },
      });
      connected.value = true;
    }
  } catch (error) {
    toast.error(error.response.data.detail);
  } finally {
    loading.value = false;
  }
};
const continueConection = async () => {
  try {
    loading.value = true;
    if (!validatePropertyIds()) {
      toast.error(
        'Please ensure all Property IDs are numbers and 8-12 digits long.',
      );
      return;
    }
    const payloadProperty = propertyIds.value.map((id) => id.toString());
    const response = await Api.patch(
      `/airbyte/google-analytics`,
      {
        property_id: payloadProperty,
      },
      {
        params: {
          source_type: sourceType,
        },
      },
    );

    if (response.status == 200) {
      toast.success('Successfully connected properties!', {
        onClose: () => {
          router.push('/settings/team/connections');
        },
      });
      connected.value = true;
    }
  } catch (error) {
    toast.error(error.response.data.detail);
  } finally {
    loading.value = false;
  }
};
onMounted(() => {
  if (route.query.secret_id) {
    toast.success('Account Authorized successfully!');
    secretId.value = route.query.secret_id;
  }
});
</script>
