<template>
  <InsightCard
    title="Top Converting Campaigns"
    column1="Campaign Name"
    column2="Share"
    column3="Conversions"
    apiUrl="/google-ads-insights/campaign-level-conversions"
    :displayIndex="index"
    :formatter="formatNumber"
    :change="3"
    srcName="gads"
  />
</template>

<script setup>
import { formatNumber } from '@/utils/helperFunctions';
import InsightCard from '../InsightCard.vue';

const index = [0, 1];
</script>
