<template>
  <div>
    <!-- Mobile Hamburger Button -->
    <div
      class="md:hidden fixed top-0 right-0 p-4 flex w-full justify-between dark:text-white bg-white dark:bg-black z-50 border-b dark:border-gray-800"
    >
      <a href="/dashboard" class="bg-white rounded-md py-1 px-2">
        <ApplicationMark class="h-6" />
      </a>
      <button @click="toggleSidebar" class="p-1" aria-label="Toggle Sidebar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <transition name="slide-in">
      <!--Settings Sidebar-->
      <aside
        class="fixed inset-0 top-0 left-0 z-50 md:w-44 bg-gray-50 dark:bg-black border-r border-gray-200 dark:border-gray-600 h-screen flex flex-col pt-4 pb-6"
        v-if="isSettingsSlideOpen"
      >
        <!-- Menu Section -->
        <div
          class="flex p-3 gap-2 justify-start items-center text-base md:text-lg font-medium rounded-md cursor-pointer text-black dark:text-white"
          @click.prevent="closeSettingsSlide"
        >
          <span class="-rotate-90"><ChevronUp :size="20" /></span>
          <span class="text-gray-800 dark:text-white">Menu</span>
        </div>

        <!-- Main Content -->
        <div class="pt-2 px-4 flex-1 flex flex-col">
          <!-- Team Settings Section -->
          <div class="">
            <h3
              class="text-base font-medium text-gray-800 dark:text-white py-2"
            >
              Team Settings
            </h3>
            <div class="space-y-1">
              <RouterLink
                v-if="currentTeam?.role === 1 || currentTeam?.role === 2"
                to="/settings/team/manage"
                class="flex items-center space-x-2 px-2 py-2 text-sm rounded-lg text-gray-700 hover:bg-gray-200 dark:text-gray-100 dark:hover:bg-gray-700"
                :class="{
                  'bg-gray-200 dark:bg-amber-500': isActive(
                    '/settings/team/manage',
                  ),
                }"
                >Manage Team</RouterLink
              >
              <RouterLink
                to="/settings/team/create"
                class="flex items-center space-x-2 px-2 py-2 text-sm rounded-lg text-gray-700 hover:bg-gray-200 dark:text-gray-100 dark:hover:bg-gray-700"
                :class="{
                  'bg-gray-200 dark:bg-amber-500': isActive(
                    '/settings/team/create',
                  ),
                }"
                >Create Team</RouterLink
              >
              <RouterLink
                v-if="currentTeam?.role === 1 || currentTeam?.role === 2"
                to="/settings/team/connections"
                class="flex items-center space-x-2 px-2 py-2 text-sm rounded-lg text-gray-700 hover:bg-gray-200 dark:text-gray-100 dark:hover:bg-gray-700"
                :class="{
                  'bg-gray-200 dark:bg-amber-500': isActive(
                    '/settings/team/connections',
                  ),
                }"
              >
                Connections</RouterLink
              >
              <RouterLink
                v-if="currentTeam?.role === 1 || currentTeam?.role === 2"
                to="/settings/team/keywords"
                class="flex items-center space-x-2 px-2 py-2 text-sm rounded-lg text-gray-700 hover:bg-gray-200 dark:text-gray-100 dark:hover:bg-gray-700"
                :class="{
                  'bg-gray-200 dark:bg-amber-500': isActive(
                    '/settings/team/keywords',
                  ),
                }"
              >
                Brand Keywords</RouterLink
              >
            </div>
          </div>
        </div>
      </aside>
      <!--Default Sidebar-->

      <aside
        v-else
        :class="[
          'fixed inset-0 top-0 left-0 bg-gray-50 dark:bg-black border-r border-gray-200 dark:border-gray-600 z-50 transition-transform md:translate-x-0 md:w-44 md:block',
          {
            'translate-x-0': isSidebarOpen,
            '-translate-x-full': !isSidebarOpen,
          },
        ]"
      >
        <nav class="flex flex-col gap-4 h-full px-2 pt-4 pb-8">
          <div
            class="grid grid-cols-6 gap-2 pb-4 border-b dark:border-gray-700 bg-gray-50"
          >
            <a
              href="/dashboard"
              class="rounded-md col-span-5 flex justify-center"
            >
              <ApplicationMark class="h-12" />
            </a>
            <button
              @click="toggleSidebar"
              class="md:hidden dark:text-white col-span-1"
              aria-label="Close Sidebar"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div class="flex flex-col flex-grow">
            <RouterLink
              to="/dashboard"
              class="dark:text-white flex items-center space-x-1 py-2 rounded-lg px-2"
              :class="{
                'bg-gray-200 dark:bg-amber-500': isActive('/dashboard'),
              }"
            >
              <ChartNoAxesCombined :size="20" />
              <span>Dashboard</span>
            </RouterLink>
            <RouterLink
              to="/insight"
              class="dark:text-white flex items-center space-x-1 py-2 rounded-lg px-2"
              :class="{ 'bg-gray-200 dark:bg-amber-500': isActive('/insight') }"
            >
              <Lightbulb :size="20" />
              <span>Insight</span>
            </RouterLink>
          </div>
          <CustomDropdown>
            <!-- Trigger Button -->
            <template #trigger>
              <button
                type="button"
                class="inline-flex items-center p-2 border text-sm leading-4 font-medium rounded-md text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 transition ease-in-out duration-150 dark:text-gray-100 w-full justify-between"
              >
                <div class="flex items-center gap-1">
                  <div
                    class="w-6 h-6 bg-gray-300 dark:bg-gray-700 rounded-full flex items-center justify-center text-white font-medium text-sm"
                  >
                    {{
                      userStore.userData?.name && userStore.userData?.name[0]
                    }}
                  </div>
                  {{ userStore.userData?.name }}
                </div>
                <ChevronUp :size="16" />
              </button>
            </template>

            <!-- Dropdown Content -->
            <template #content>
              <div class="bg-white dark:bg-gray-900 shadow-lg rounded-md py-2">
                <!-- User Info -->
                <div class="flex items-center gap-1 px-2 py-3">
                  <div
                    class="w-8 h-8 bg-gray-300 dark:bg-gray-700 rounded-full flex items-center justify-center text-white font-medium text-sm"
                  >
                    {{
                      userStore.userData?.name && userStore.userData?.name[0]
                    }}
                  </div>
                  <div>
                    <div
                      class="font-medium text-gray-900 dark:text-gray-100 text-sm"
                    >
                      {{ userStore.userData?.name }}
                    </div>
                    <div class="text-xs text-gray-500 dark:text-gray-400">
                      {{ userStore.userData?.email }}
                    </div>
                  </div>
                </div>
                <!-- Divider -->
                <div
                  class="border-t border-gray-200 dark:border-gray-700 my-1"
                ></div>

                <!-- Switch Teams Section -->
                <div
                  class="relative"
                  @mouseenter="openTeamsDropdown"
                  @mouseleave="closeTeamsDropdown"
                >
                  <div class="py-1">
                    <!-- Switch Teams Label -->
                    <div
                      class="block px-4 text-sm text-gray-400 dark:text-gray-500"
                    >
                      Team
                    </div>

                    <!-- Dropdown Trigger -->
                    <div class="my-1">
                      <button
                        type="button"
                        class="flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 cursor-pointer w-full"
                        @click="toggleTeamsDropdown"
                      >
                        <div class="flex items-center gap-2">
                          <UsersRound :size="16" />
                          <span>{{ currentTeam?.name || 'Select Team' }}</span>
                        </div>
                        <ChevronRight
                          :size="16"
                          :class="{
                            'rotate-90': isTeamsDropdownOpen,
                          }"
                          class="transition-transform"
                        />
                      </button>
                    </div>

                    <!-- Dropdown Content -->
                    <div
                      v-if="isTeamsDropdownOpen"
                      class="absolute z-50 bg-white dark:bg-gray-900 rounded-lg shadow-lg md:rounded-md md:translate-x-full top-full md:top-5 right-0 left-0 mx-2 md:mx-0"
                    >
                      <ul class="p-2 space-y-2">
                        <!-- Team Options -->
                        <li
                          v-for="team in teams"
                          :key="team.id"
                          class="flex items-center justify-between px-4 py-2 text-xs md:text-sm text-gray-700 bg-gray-100 dark:text-gray-200 dark:bg-gray-800 rounded hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer"
                          @click.prevent="switchToTeam(team)"
                        >
                          <span>{{ team.name }}</span>
                          <svg
                            v-if="currentTeam?.id === team.id"
                            class="h-4 w-4 text-green-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- Settings Option -->
                <div
                  class="flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 rounded-md cursor-pointer"
                  @click="openSettingsSlide"
                >
                  <div class="flex items-center gap-2">
                    <Settings :size="16" />
                    <span>Settings</span>
                  </div>
                </div>

                <!-- Divider -->
                <div
                  class="border-t border-gray-200 dark:border-gray-700 my-1"
                ></div>
                <!-- Profile Link -->
                <div
                  class="px-2 py-1 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                  :class="{
                    'bg-gray-200 dark:bg-amber-500':
                      isActive('/settings/profile'),
                  }"
                >
                  <RouterLink
                    to="/settings/profile"
                    class="flex items-center space-x-2 px-2 py-2 text-sm text-gray-700 dark:text-gray-100"
                  >
                    <User :size="18" />
                    <span>Profile</span>
                  </RouterLink>
                </div>

                <!-- Theme switch -->
                <div
                  class="relative"
                  @mouseenter="openThemeDropdown"
                  @mouseleave="closeThemeDropdown"
                >
                  <div class="">
                    <div class="my-1">
                      <button
                        type="button"
                        class="flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 cursor-pointer w-full"
                        @click="toggleThemeDropdown"
                      >
                        <div class="flex items-center gap-2">
                          <Palette :size="16" />
                          <span>Appearance</span>
                        </div>
                        <ChevronRight
                          :size="16"
                          :class="{
                            'rotate-90': isThemeDropdownOpen,
                          }"
                          class="transition-transform"
                        />
                      </button>
                    </div>

                    <!-- Dropdown Content -->
                    <div
                      v-if="isThemeDropdownOpen"
                      class="absolute z-50 bg-white dark:bg-gray-900 md:translate-x-full top-full md:top-0 md:left-1/3 mx-2 md:mx-0 border border-gray-200 dark:border-gray-700 w-2/3"
                    >
                      <ul class="space-y-2">
                        <li
                          v-for="option in ['light', 'dark']"
                          :key="option"
                          @click="toggleTheme(option)"
                          class="flex items-center justify-between p-2 text-sm cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 transition dark:text-white text-black indent-2"
                          :class="
                            theme === option
                              ? 'bg-gray-100 dark:bg-gray-800 font-semibold'
                              : ''
                          "
                        >
                          <span class="capitalize">{{ option }}</span>
                          <span v-if="theme === option" class="text-green-500">
                            ✓
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- Logout Option -->
                <div
                  class="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 rounded-md cursor-pointer"
                >
                  <Logout />
                </div>
              </div>
            </template>
          </CustomDropdown>
        </nav>
      </aside>
    </transition>

    <div
      :class="[
        'mt-14 md:mt-0 md:ml-44 flex-1 transition-transform',
        { 'ml-0': !isSidebarOpen },
      ]"
    >
      <RouterView />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import {
  ChevronRight,
  ChartNoAxesCombined,
  ChevronUp,
  Lightbulb,
  Settings,
  User,
  UsersRound,
  Palette,
} from 'lucide-vue-next';
import { useTeamStore } from '@/stores/teamStore';
import { useUserStore } from '@/stores/userStore';
import Logout from '@/pages/Auth/Logout.vue';
import ApplicationMark from '@/components/ApplicationMark.vue';
import { toast } from 'vue3-toastify';
import CustomDropdown from '@/components/CustomDropdown.vue';
import Api from '@/utils/ApiClient';

const userStore = useUserStore();
const teamStore = useTeamStore();

const teams = ref([]);
const isSidebarOpen = ref(false);
const isTeamsDropdownOpen = ref(false);
const isThemeDropdownOpen = ref(false);
const currentTeam = ref(teamStore.current_team);
const theme = ref(localStorage.getItem('theme') || 'light');
const isSettingsSlideOpen = ref(
  JSON.parse(localStorage.getItem('settingSidebarOpen')) || false,
);

const openSettingsSlide = () => {
  localStorage.setItem('settingSidebarOpen', JSON.stringify(true));
  isSettingsSlideOpen.value = true;
};

const closeSettingsSlide = () => {
  localStorage.setItem('settingSidebarOpen', JSON.stringify(false));
  isSettingsSlideOpen.value = false;
};

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value;
};

const openTeamsDropdown = () => {
  isTeamsDropdownOpen.value = true;
};
const closeTeamsDropdown = () => {
  isTeamsDropdownOpen.value = false;
};
const toggleTeamsDropdown = () => {
  isTeamsDropdownOpen.value = !isTeamsDropdownOpen.value;
};

const openThemeDropdown = () => {
  isThemeDropdownOpen.value = true;
};
const closeThemeDropdown = () => {
  isThemeDropdownOpen.value = false;
};
const toggleThemeDropdown = () => {
  isThemeDropdownOpen.value = !isThemeDropdownOpen.value;
};

const isActive = (path) => useRoute().path === path;

const switchToTeam = (team) => {
  teamStore.setCurrentTeam(team);
  isTeamsDropdownOpen.value = false;
  toast(`Switched to ${team.name} team`, {
    theme: 'light',
    transition: 'zoom',
    type: 'info',
    autoClose: 2000,
    position: 'top-center',
    dangerouslyHTMLString: true,
  });
};

const fetchProfileData = async () => {
  try {
    const response = await Api.get('/settings');
    if (response?.data?.id) {
      const userData = response.data;
      if (!userStore.userData?.id) {
        userStore.setUser(userData);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const toggleTheme = (selectedTheme) => {
  theme.value = selectedTheme;
  localStorage.setItem('theme', selectedTheme);
  document.documentElement.classList.toggle('dark', selectedTheme === 'dark');
};

const fetchUserTeams = async () => {
  try {
    const response = await Api.get('/team');
    if (response.data) {
      teamStore.setTeams(response.data.user_teams);
      teamStore.refetchNewTeamsFlag = false;
    }
  } catch (error) {
    console.log(error);
  }
};

const fetchData = () => {
  fetchProfileData();
  fetchUserTeams();
};

watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  { immediate: true, deep: true },
);

watch(
  () => teamStore.teams,
  (newTeams) => {
    teams.value = [...newTeams];
  },
  { immediate: true, deep: true },
);

watch(
  () => teamStore.refetchNewTeamsFlag,
  () => {
    if (teamStore.refetchNewTeamsFlag) {
      fetchUserTeams();
    }
  },
  { immediate: true, deep: true },
);
onMounted(() => {
  const savedTheme = localStorage.getItem('theme') || 'light';
  theme.value = savedTheme;
  document.documentElement.classList.toggle('dark', savedTheme === 'dark');
  fetchData();
});
</script>
