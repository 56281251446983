<template>
  <MetricCard
    apiEndpoint="/google-search-console/impressions"
    metricName="IMPRESSIONS"
    :formatData="formatImpressions"
    srcName="Google Search Console"
  />
</template>

<script setup>
import { formatNumber } from '@/utils/helperFunctions';
import MetricCard from '../MetricCard.vue';

const formatImpressions = (data) => formatNumber(data.data);
</script>
