<template>
  <div
    class="rounded-lg p-6 md:p-10 w-full dark:bg-black min-h-screen bg-gray-100 h-full flex flex-col justify-center items-center shadow-lg space-y-6"
  >
    <div
      v-if="loading"
      class="bg flex flex-col justify-center items-center h-full w-full"
    >
      <div class="animate-spin"><Settings :size="40" /></div>

      <div class="text-lg font-semibold font-mono">Connecting your account</div>
    </div>
    <div v-else>
      <!-- Header with Google Ads Logo -->
      <div class="flex items-center justify-center mb-6">
        <img
          src="@/assets/google-ads.png"
          alt="Google Ads Logo"
          class="h-8 w-8 mr-3"
        />
        <div
          v-if="connected"
          class="font-medium text-gray-800 dark:text-gray-100 text-center lg:text-lg text-base h-full w-full p-4"
        >
          Connected Successfully . Navigating you back .....
        </div>
        <div class="text-2xl font-bold text-gray-800 dark:text-gray-100" v-else>
          Select a Google Ads Account to Sync Data
        </div>
      </div>
      <div v-if="!connected">
        <!-- Instruction Text -->
        <p class="text-gray-600 dark:text-gray-300 text-center mb-4">
          Please enter the Google Ads account you'd like to connect. Only one
          account can be connected at a time.
        </p>

        <!-- Customer List -->
        <div>
          <div class="text-xl p-2 dark:text-white">Customer ID:</div>

          <TextInput
            id="customerId"
            v-model="inputData"
            type="text"
            class="mt-1 block w-full p-2"
            required
            autocomplete="customerId"
            :disabled="loading"
          />
        </div>
        <!-- Connect Button -->
        <div class="flex justify-end mt-8">
          <button
            v-if="secretId"
            @click="establishConnection"
            :disabled="!inputData"
            class="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Connect to Google Ads
          </button>
          <button
            v-else
            @click="continueConection"
            :disabled="!inputData"
            class="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Continue Selecting Customer ID
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useTeamStore } from '@/stores/teamStore';
import Api from '@/utils/ApiClient';
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import TextInput from './TextInput.vue';
import { Settings } from 'lucide-vue-next';

const secretId = ref();
const sourceType = 'google-ads';
const inputData = ref();
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const teamStore = useTeamStore();
const connected = ref(false);
const currentTeam = ref(teamStore.current_team);
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
    deep: true,
  },
);
const validateCustomerId = () => {
  return inputData.value.length == 10;
};
const establishConnection = async () => {
  try {
    loading.value = true;
    if (!validateCustomerId()) {
      toast.error(
        'Please ensure all customer IDs are valid and 10 digits long.',
      );
      return;
    }
    const response = await Api.post(
      `/airbyte/google-ads`,
      {
        customer_id: inputData.value.toString(),
        secret_id: secretId.value,
      },
      {
        params: {
          source_type: sourceType,
        },
      },
    );
    if (response.status == 200) {
      toast.success('Account Connected successfully', {
        onClose: () => {
          router.push('/settings/team/connections');
        },
      });
      connected.value = true;
    }
  } catch (error) {
    toast.error(error.response.data.detail);
  } finally {
    loading.value = false;
  }
};
const continueConection = async () => {
  try {
    loading.value = true;
    if (!validateCustomerId()) {
      toast.error(
        'Please ensure all customer IDs are valid and 10 digits long.',
      );
      return;
    }
    const response = await Api.patch(
      `/airbyte/google-ads`,
      {
        customer_id: inputData.value.toString(),
      },
      {
        params: {
          source_type: sourceType,
        },
      },
    );
    if (response.status == 200) {
      toast.success('Account Connected successfully', {
        onClose: () => {
          router.push('/settings/team/connections');
        },
      });
      connected.value = true;
    }
  } catch (error) {
    toast.error(error.response.data.detail);
  } finally {
    loading.value = false;
  }
};
onMounted(() => {
  if (route.query.secret_id) {
    toast.success('Account Authorized successfully!');
    secretId.value = route.query.secret_id;
  }
});
</script>
