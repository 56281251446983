<template>
  <div class="bg-gray-200 min-h-screen w-full flex flex-col">
    <nav class="flex justify-end py-4 px-6 text-xl space-x-2 bg-gray-100">
      <img src="/images/logo.svg" alt="logo" class="h-8 absolute left-0 ml-5" />
      <a
        href="/login"
        class="rounded-md px-3 py-2 text-sm font-medium hover:bg-gray-700 text-white bg-gray-900"
      >
        Log in
      </a>

      <a
        href="/register"
        class="rounded-md px-3 py-2 text-sm font-medium hover:bg-gray-700 text-white bg-gray-900"
      >
        Register
      </a>
    </nav>
    <main
      class="space-y-4 flex flex-col items-center bg-white max-w-2xl mx-auto rounded-xl my-16 p-12 text-center"
    >
      <img src="/images/logo.svg" alt="logo" class="h-16" />
      <h1 class="font-bold text-3xl">
        All your growth metrics. One platform. Zero guesswork.
      </h1>
      <p class="text-gray-600">
        We bring all your essential growth metrics into a single, unified
        dashboard. No more tab hopping. No more data drowning. Just clear,
        actionable insights at your fingertips.
      </p>
      <p class="text-gray-600">
        DViO One is powered by AI that doesn't just crunch numbers—it
        understands them. It finds the connections, spots the trends, and
        highlights what actually matters. We are currently in beta but you can
        get early access.<br />
        <a
          href="https://www.dviodigital.com/contact"
          target="_blank"
          class="underline hover:text-orange-700"
          >Fill out this form</a
        >
        and we'll reach out to you.
      </p>

      <router-link class="underline hover:text-yellow-600" to="/privacy-policy">
        Privacy Policy
      </router-link>
    </main>
  </div>
</template>
