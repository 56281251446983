<template>
  <div
    class="bg-white dark:bg-gray-900 shadow-lg border border-gray-200 dark:border-gray-700 rounded-lg p-6 w-full space-y-6 md:space-y-8"
  >
    <div class="grid grid-cols-1 md:grid-cols-12 gap-6">
      <div class="col-span-12 md:col-span-5 space-y-4">
        <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">
          Add Keywords That Represent Your Brand
        </h3>
        <p class="text-sm text-gray-600 dark:text-gray-400 leading-relaxed">
          Keywords are essential for analyzing user behavior. Add variations or
          typos that people may use when searching for your brand. For example:
          <span class="font-medium text-gray-800 dark:text-gray-200">
            "Google" → "Gogle", "Gooogle".
          </span>
        </p>
        <ul class="space-y-2">
          <li class="grid grid-cols-12 space-x-2">
            <SquareCheckBig
              class="text-indigo-600 col-span-1 dark:text-indigo-400"
            />
            <span class="text-sm text-gray-600 col-span-11 dark:text-gray-400">
              Avoid generic terms or irrelevant words.
            </span>
          </li>
          <li class="grid grid-cols-12 space-x-2">
            <SquareCheckBig
              class="text-indigo-600 col-span-1 dark:text-indigo-400"
            />
            <span class="text-sm text-gray-600 col-span-11 dark:text-gray-400">
              Add brand-specific custom keywords.
            </span>
          </li>

          <li class="grid grid-cols-12 space-x-2">
            <SquareCheckBig
              class="text-indigo-600 col-span-1 dark:text-indigo-400"
            />
            <span class="text-sm text-gray-600 col-span-11 dark:text-gray-400">
              Avoid using competitor names as keywords.
            </span>
          </li>
          <li class="grid grid-cols-12 space-x-2">
            <SquareCheckBig
              class="text-indigo-600 col-span-1 dark:text-indigo-400"
            />
            <span class="text-sm text-gray-600 col-span-11 dark:text-gray-400">
              Include commonly misspelled versions of your brand name.
            </span>
          </li>
          <li class="grid grid-cols-12 space-x-2">
            <SquareCheckBig
              class="text-indigo-600 col-span-1 dark:text-indigo-400"
            />
            <span class="text-sm text-gray-600 col-span-11 dark:text-gray-400">
              Periodically review and update your keywords to match trends.
            </span>
          </li>
        </ul>
      </div>

      <div class="col-span-12 md:col-span-7 space-y-3">
        <div
          v-if="suggested.length"
          class="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-4 shadow-sm"
        >
          <h3 class="text-sm font-medium text-gray-700 dark:text-gray-300">
            Suggested Keywords:
          </h3>
          <div class="flex flex-wrap gap-2 mt-2">
            <span
              v-for="(keyword, index) in suggested"
              :key="index"
              class="inline-block bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-300 text-xs font-medium px-3 py-1 rounded-full cursor-pointer hover:bg-indigo-200 dark:hover:bg-indigo-800"
              @click="selectSuggestedTag(keyword)"
            >
              {{ keyword }}
            </span>
          </div>
        </div>

        <div
          class="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-4 shadow-sm"
        >
          <VueMultiselect
            v-model="value"
            :options="options"
            :searchable="true"
            :multiple="true"
            :close-on-select="true"
            :taggable="true"
            placeholder="Pick or add your Brand Keywords"
            tag-placeholder="Add this as a new keyword"
            label="name"
            :max="10"
            track-by="name"
            :hideSelected="true"
            @tag="addCustomOption"
          />
          <p class="text-xs text-gray-500 dark:text-gray-400 mt-2">
            Tip: Press <span class="font-semibold">Enter</span> to add new
            keywords.
          </p>
        </div>

        <div
          class="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-4 shadow-sm flex flex-col sm:flex-row justify-between gap-4"
        >
          <button
            type="button"
            class="w-full bg-red-500 hover:bg-red-600 disabled:bg-gray-500 text-white font-semibold py-2 rounded-md shadow transition duration-200"
            :disabled="!value.length"
            @click="resetAllKeywords"
          >
            Replace All Keywords
          </button>
          <button
            type="submit"
            class="w-full bg-blue-500 hover:bg-blue-600 disabled:bg-gray-500 text-white font-semibold py-2 rounded-md shadow transition duration-200"
            :disabled="!value.length"
            @click="handleSubmit"
          >
            Add New Keywords
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTeamStore } from '@/stores/teamStore';
import Api from '@/utils/ApiClient';
import { SquareCheckBig } from 'lucide-vue-next';
import { ref, watch } from 'vue';
import VueMultiselect from 'vue-multiselect';
import { toast } from 'vue3-toastify';
const value = ref([]);
const options = ref([]);
const suggested = ref([]);
const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const selectSuggestedTag = (newTag) => {
  const normalizedTag = newTag.toLowerCase().trim();
  if (
    value.value &&
    value.value.some((item) => item.name.toLowerCase() === normalizedTag)
  ) {
    toast.info('Keyword Already Selected');
    return;
  }
  if (!/^[A-Za-z]+$/.test(normalizedTag)) {
    toast.error('Keyword should contain only letters');
    return;
  }

  const newOption = { name: normalizedTag };
  if (
    options.value &&
    !options.value.some((item) => item.name.toLowerCase() === normalizedTag)
  ) {
    options.value.push(newOption);
  }
  if (value.value && !value.value.find((item) => item.name == newOption.name)) {
    value.value.push(newOption);
  }
};
const addCustomOption = (newTag) => {
  const normalizedTag = newTag.toLowerCase().trim();
  if (
    value.value &&
    value.value.some((item) => item.name.toLowerCase() === normalizedTag)
  ) {
    toast.info('Keyword Already Selected');
    return;
  }
  if (!/^[A-Za-z]+$/.test(normalizedTag)) {
    toast.error('Keyword should contain only letters');
    return;
  }
  const newOption = { name: normalizedTag };
  if (
    options.value &&
    !options.value.some((item) => item.name.toLowerCase() === normalizedTag)
  ) {
    options.value.push(newOption);
  }
  if (value.value && !value.value.find((item) => item.name == newOption.name)) {
    value.value.push(newOption);
  }
};
const fetchTeamsKeywords = async () => {
  try {
    options.value = [];
    value.value = [];
    const response = await Api.get(`/keyword/brand-keywords`);
    if (response.data.length) {
      const keywords = response.data.map((keyword) => ({ name: keyword }));
      options.value = keywords;
      value.value = keywords;
    }
  } catch (error) {
    options.value = [];
    value.value = [];
    toast.error('Error fetching team keywords');
    console.error(error);
  }
};
const handleSubmit = async () => {
  try {
    const keywords = value.value.map((item) => item.name);
    const response = await Api.post(`/keyword/brand-keywords`, {
      keywords,
    });
    if (response.status === 201) {
      toast.success('Keywords Added Successfully');
      fetchTeamsKeywords(currentTeam.value);
    }
  } catch (error) {
    toast.error('Failed to add keywords');
    console.error(error);
  }
};
const generateSuggestions = (teamName) => {
  if (!teamName) return;
  suggested.value =
    teamName.length < 3
      ? [
          `${teamName.slice(0, 3).toLowerCase()}`,
          `${teamName.slice(0, 4).toLowerCase()}`,
          `${teamName.slice(0, 5).toLowerCase()}`,
        ]
      : [
          `${teamName.slice(0, 2).toLowerCase()}`,
          `${teamName.slice(0, 3).toLowerCase()}`,
        ];
};
const resetAllKeywords = async () => {
  try {
    const keywords = value.value.map((item) => item.name);
    const response = await Api.put(`/keyword/brand-keywords`, {
      keywords,
    });
    if (response.status === 200) {
      toast.success('Keywords Updated');
      fetchTeamsKeywords(currentTeam.value);
    }
  } catch (error) {
    toast.error('Failed to update keywords');
    console.error(error);
  }
};
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
    if (newTeam) {
      fetchTeamsKeywords(newTeam);
      generateSuggestions(newTeam.name);
    }
  },
  { deep: true, immediate: true },
);
</script>
