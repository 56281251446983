<script setup>
import { computed } from 'vue';
import axios from 'axios';
import ActionMessage from '@/components/ActionMessage.vue';
import FormSection from '@/components/FormSection.vue';
import InputError from '@/components/InputError.vue';
import InputLabel from '@/components/InputLabel.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';
import TextInput from '@/components/TextInput.vue';

const props = defineProps({
  user: Object,
});

const form = computed(() => {
  return {
    _method: 'PUT',
    name: props.user.name,
    email: props.user.email,
    errors: {},
    processing: true,
    recentlySuccessful: false,
  };
});

const updateProfileInformation = async () => {
  form.value.processing = true;
  form.value.errors = {};
  form.value.recentlySuccessful = false;

  const formData = new FormData();
  for (const [key, value] of Object.entries(form.value)) {
    formData.append(key, value);
  }

  try {
    await Api.post('/api/user-profile-information/update', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    form.value.recentlySuccessful = true;
    form.value.name = '';
    form.value.email = '';
  } catch (error) {
    form.value.errors = error.response.data.errors || {};

    if (form.value.errors.password) {
      form.value.password = '';
      form.value.password_confirmation = '';
    }

    if (form.value.errors.current_password) {
      form.value.current_password = '';
    }
  } finally {
    form.value.processing = false;
  }
};
</script>

<template>
  <FormSection @submitted="updateProfileInformation">
    <template #title> Profile Information </template>

    <template #description>
      Update your account's profile information and email address.
    </template>

    <template #form>
      <!-- Name -->
      <div class="col-span-6 sm:col-span-4">
        <InputLabel for="name" value="Name" />
        <TextInput
          id="name"
          v-model="form.name"
          type="text"
          class="mt-1 block w-full"
          required
          autocomplete="name"
          :disabled="form.processing"
        />
        <InputError :message="form.errors.name" class="mt-2" />
      </div>

      <!-- Email -->
      <div class="col-span-6 sm:col-span-4">
        <InputLabel for="email" value="Email" />
        <TextInput
          id="email"
          v-model="form.email"
          type="email"
          class="mt-1 block w-full"
          required
          autocomplete="username"
          :disabled="form.processing"
        />
        <InputError :message="form.errors.email" class="mt-2" />

        <div class="col-span-6 sm:col-span-4 p-1">
          <p class="text-sm mt-2">Your email address is verified.</p>
        </div>
      </div>
    </template>

    <template #actions>
      <ActionMessage :on="form.recentlySuccessful" class="me-3">
        Saved.
      </ActionMessage>

      <PrimaryButton
        :class="{ 'opacity-25': form.processing }"
        :disabled="form.processing"
      >
        Save
      </PrimaryButton>
    </template>
  </FormSection>
</template>
