<template>
  <div
    v-if="processing"
    class="flex h-full w-full justify-center items-center min-h-screen flex-col"
  >
    <div class="animate-spin"><Settings :size="40" /></div>

    <div class="text-lg font-semibold font-mono">Setting up your account</div>
  </div>
  <AuthenticationCard v-else>
    <template #logo>
      <AuthenticationCardLogo />
    </template>

    <form @submit.prevent="submit" novalidate>
      <div>
        <InputLabel for="firstName" value="First Name" />
        <TextInput
          id="firstName"
          v-model="firstName"
          type="text"
          class="mt-1 block w-full"
          required
          autofocus
          autocomplete="name"
        />
        <InputError class="mt-2" :message="errors.firstName" />
      </div>
      <div class="mt-4">
        <InputLabel for="lastName" value="Last Name" />
        <TextInput
          id="lastName"
          v-model="lastName"
          type="text"
          class="mt-1 block w-full"
          required
          autofocus
          autocomplete="name"
        />
        <InputError class="mt-2" :message="errors.lastName" />
      </div>

      <div class="mt-4">
        <InputLabel for="email" value="Email" />
        <TextInput
          id="email"
          v-model="email"
          type="email"
          class="mt-1 block w-full"
          required
          autocomplete="username"
        />
        <InputError class="mt-2" :message="errors.email" />
      </div>
      <div class="mt-4">
        <InputLabel for="team_name" value="Team Name" />
        <TextInput
          id="team_name"
          v-model="teamName"
          type="email"
          class="mt-1 block w-full"
          required
        />
        <InputError class="mt-2" :message="errors.teamName" />
      </div>

      <div class="mt-4 relative">
        <div class="flex items-center">
          <div class="flex-1">
            <InputLabel for="password" value="Password" />
            <TextInput
              id="password"
              v-model="password"
              type="password"
              class="mt-1 block w-full"
              required
              autocomplete="new-password"
            />
          </div>
          <button
            type="button"
            @click="showPasswordInstructions = !showPasswordInstructions"
            class="ml-2 mt-6 text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle cx="12" cy="12" r="10" />
              <line x1="12" y1="16" x2="12" y2="12" />
              <line x1="12" y1="8" x2="12" y2="8" />
            </svg>
          </button>
        </div>
        <div v-if="showPasswordInstructions" class="mt-2 text-sm text-gray-600">
          Password must be at least 8 characters long and include at least:
          <ul class="list-disc pl-5 mt-1">
            <li>One uppercase letter</li>
            <li>One lowercase letter</li>
            <li>One number</li>
            <li>One special character (e.g., @, $, !, %, #)</li>
          </ul>
        </div>
        <InputError class="mt-2" :message="errors.password" />
      </div>

      <div class="mt-4">
        <InputLabel for="password_confirmation" value="Confirm Password" />
        <TextInput
          id="password_confirmation"
          v-model="passwordConfirmation"
          type="password"
          class="mt-1 block w-full"
          required
          autocomplete="new-password"
        />
        <InputError class="mt-2" :message="errors.passwordConfirmation" />
      </div>

      <div class="flex items-center justify-end mt-4">
        <router-link
          to="/login"
          class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Already registered?
        </router-link>

        <PrimaryButton
          class="ms-4"
          :class="{ 'opacity-25': processing }"
          :disabled="processing"
        >
          Register
        </PrimaryButton>
      </div>
    </form>
  </AuthenticationCard>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import AuthenticationCard from '@/components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/components/AuthenticationCardLogo.vue';
import InputError from '@/components/InputError.vue';
import InputLabel from '@/components/InputLabel.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';
import TextInput from '@/components/TextInput.vue';
import { toast } from 'vue3-toastify';
import Api from '@/utils/ApiClient';
import { Settings } from 'lucide-vue-next';
import {
  validateEmail,
  validatePassword,
  validatePasswordConfirmation,
  validateRequired,
} from '@/utils/validator';

const firstName = ref('');
const lastName = ref('');
const email = ref('');
const teamName = ref('');
const password = ref('');
const passwordConfirmation = ref('');
const processing = ref(false);
const errors = ref({});
const showPasswordInstructions = ref(false);
const router = useRouter();

const validate = () => {
  let isValid = true;
  errors.value = {};
  errors.value.firstName = validateRequired(firstName.value, 'First Name');
  errors.value.lastName = validateRequired(lastName.value, 'Last Name');
  errors.value.teamName = validateRequired(teamName.value, 'Team Name');
  errors.value.email = validateEmail(email.value);
  errors.value.password = validatePassword(password.value);
  errors.value.passwordConfirmation = validatePasswordConfirmation(
    password.value,
    passwordConfirmation.value,
  );

  isValid = Object.values(errors.value).every((error) => !error);
  return isValid;
};

const submit = async () => {
  processing.value = true;

  if (!validate()) {
    processing.value = false;
    return;
  }

  const emailLowerCase = email.value.toLowerCase();
  const apiData = {
    first_name: firstName.value,
    last_name: lastName.value,
    email: emailLowerCase,
    password: password.value,
    team_name: teamName.value,
  };

  try {
    const response = await Api.post('/auth/register', apiData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 201) {
      router
        .push({ path: '/verify', query: { email: emailLowerCase } })
        .then(() => {
          toast.success('User registered successfully.', {
            position: 'top-center',
            autoClose: 2000,
            transition: 'zoom',
          });
        });
    } else {
      errors.value = response.data.errors || {};
      toast.error('User registration failed.', {
        position: 'top-center',
        autoClose: 2000,
        transition: 'zoom',
      });
    }
  } catch (error) {
    let errorMessage = 'Error registering user.';
    if (error.response) {
      if (error.response.status === 400) {
        errorMessage = error.response.data.detail || errorMessage;
      }
    }
    toast.error(errorMessage, {
      position: 'top-center',
      autoClose: 2000,
      transition: 'zoom',
    });
  } finally {
    processing.value = false;
    email.value = '';
    firstName.value = '';
    lastName.value = '';
    teamName.value = '';
    password.value = '';
    passwordConfirmation.value = '';
  }
};
</script>
