<template>
  <AuthenticationCard>
    <template #logo>
      <AuthenticationCardLogo />
    </template>

    <form @submit.prevent="submit" novalidate>
      <div>
        <InputLabel for="email" value="Email" />
        <TextInput
          id="email"
          v-model="email"
          type="email"
          class="mt-1 block w-full"
          required
          autofocus
          autocomplete="username"
        />
        <InputError class="mt-2" :message="errors.email" />
      </div>

      <div class="mt-4">
        <InputLabel for="password" value="Password" />
        <TextInput
          id="password"
          v-model="password"
          type="password"
          class="mt-1 block w-full"
          required
          autocomplete="current-password"
        />
        <InputError class="mt-2" :message="errors.password" />
      </div>

      <div class="mt-2 text-end">
        <router-link
          to="/forgot-password"
          class="text-sm text-gray-600 hover:text-gray-900"
        >
          Forgot Password?
        </router-link>
      </div>
      <button
        class="px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 transition ease-in-out duration-150 mt-2 w-full"
        :class="{ 'opacity-25': processing }"
        :disabled="processing"
      >
        Log in
      </button>
      <div class="flex justify-center m-2 mt-4">
        <router-link
          to="/register"
          class="text-sm text-gray-600 hover:text-gray-900 self-end"
        >
          Not registered yet?
          <span class="text-blue-500">Create a new Account</span>
        </router-link>
      </div>
    </form>
  </AuthenticationCard>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AuthenticationCard from '@/components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/components/AuthenticationCardLogo.vue';
import InputError from '@/components/InputError.vue';
import InputLabel from '@/components/InputLabel.vue';
import TextInput from '@/components/TextInput.vue';
import { toast } from 'vue3-toastify';
import { useAuthStore } from '@/stores/authStore';
import Api from '@/utils/ApiClient';
import { validateEmail, validatePassword } from '@/utils/validator';

const email = ref('');
const password = ref('');
const processing = ref(false);
const errors = ref({ email: '', password: '' });
const authStore = useAuthStore();

const router = useRouter();
const route = useRoute();

const validate = () => {
  let isValid = true;
  errors.value = { email: '', password: '' };
  errors.value.email = validateEmail(email.value);
  errors.value.password = validatePassword(password.value);
  isValid = Object.values(errors.value).every((error) => !error);
  return isValid;
};

const submit = async () => {
  processing.value = true;

  if (!validate()) {
    processing.value = false;
    return;
  }

  const formData = new URLSearchParams();
  formData.append('username', email.value.toLowerCase());
  formData.append('password', password.value);

  try {
    const response = await Api.post('/auth/login', formData.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const data = response.data;

    if (response.status === 200) {
      authStore.login(data.access_token);
      const redirectPath = route.query.redirect || '/dashboard';
      router.push({ path: redirectPath }).then(() =>
        toast.success('Login successful', {
          position: 'top-center',
          autoClose: 2000,
          transition: 'zoom',
        }),
      );
    } else {
      errors.value = data.errors || {};
      toast.error('Login failed', {
        position: 'top-center',
        autoClose: 2000,
        transition: 'zoom',
      });
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.detail || 'An error occurred during login';
    toast.error(errorMessage, {
      position: 'top-center',
      autoClose: 2000,
      transition: 'zoom',
    });
  } finally {
    processing.value = false;
    email.value = '';
    password.value = '';
  }
};
</script>
